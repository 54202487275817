import * as THREE from "three"
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader.js"
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls"
import _ from "lodash"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ThreeHero {
  constructor(block) {
    this.block = block
    this.box = block.querySelector(".three-hero__box")
    this.canvas = block.querySelector(".three-hero__canvas")
    this.setSize()
    this.gsapInit()
    this.threeInit()
    this.phone()
    this.phone2()
    this.animate()
    this.events()
    // this.isLowEndDevice()
  }

  events() {
    this.canvas?.addEventListener("webglcontextlost", () => {
      this.renderer.dispose()
      this.canvas.classList.add("three-hero__canvas--hidden")
      console.log("Context lost, canvas hidden.")
    })
  }

  setSize() {
    this.innerWidth = this.block.querySelector(".col").offsetWidth
    this.height = innerWidth >= 992 ? this.block.offsetHeight - 80 : 500
    this.width = innerWidth >= 992 ? this.halfWidth() : this.innerWidth
  }

  halfWidth() {
    return Math.max(Math.min(this.innerWidth / 2 + (innerWidth / 2 - this.innerWidth / 2) / 4, innerWidth / 2), this.block.offsetHeight)
  }

  gsapInit() {
    //Timeline
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: "top bottom",
        end: "bottom center",
        // scrub: true,
      },
      ease: "power3.out",
    })

    this.timeline.to(
      this.box,
      {
        opacity: 1,
        y: "0%",
        duration: 0.5,
        delay: 1,
        ease: "power1.out",
        onComplete: () => {
          this.box.classList.add("three-hero__box--active")
        },
      },
      0
    )
  }

  threeInit() {
    //Clock
    this.clock = new THREE.Clock()

    //Loader
    this.GLTFLoader = new GLTFLoader()

    //Scene
    this.scene = new THREE.Scene()
    this.scene.background = null

    //Camera
    this.camera = new THREE.PerspectiveCamera(30, this.width / this.height, 0.1, 1000)
    this.camera.position.set(0, 0, 1)
    this.scene.add(this.camera)

    //Renderer
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      canvas: this.canvas,
      powerPreference: "low-power",
    })
    this.renderer.setSize(this.width, this.height)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    // this.block.appendChild(this.renderer.domElement)

    //Orbit Controls
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    //Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.1)
    this.scene.add(ambientLight)

    const pointLight = new THREE.PointLight(0xffffff, 5)
    pointLight.position.set(-0.25, 0.5, 2)
    this.scene.add(pointLight)

    // const secondaryLight = new THREE.PointLight(0xffffff, 3);
    // secondaryLight.position.set(1, -1, 1);
    // this.scene.add(secondaryLight);

    const backLight1 = new THREE.DirectionalLight(0xffffff, 3)
    backLight1.position.set(-1, 0, -0.5)
    this.scene.add(backLight1)

    const backLight2 = new THREE.DirectionalLight(0xffffff, 3)
    backLight2.position.set(1, 0, -0.5)
    this.scene.add(backLight2)

    //Mouse tracking
    this.mouse = {
      x: 0,
      y: 0,
    }

    window.addEventListener("mousemove", (e) => {
      this.mouse.x = (e.clientX / innerWidth) * 2 - 1
      this.mouse.y = -(e.clientY / innerHeight) * 2 + 1
    })

    window.addEventListener("touchmove", (e) => {
      this.mouse.x = (e.touches[0].clientX / innerWidth) * 2 - 1
      this.mouse.y = -(e.touches[0].clientY / innerHeight) * 2 + 1
      // console.log(e);
    })

    //Resize
    window.addEventListener(
      "resize",
      _.debounce(() => {
        this.setSize()
        this.camera.aspect = this.width / this.height
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(this.width, this.height)
        // console.log("Resized!");
      }, 250)
    )
  }

  phone() {
    this.GLTFLoader.load(
      `/wp-content/themes/designbypelling/js/src/models/phone-checkout.glb`,
      (gltf) => {
        // console.log(gltf);
        this.phoneScene = gltf.scene
        this.phoneScene.scale.set(0.7, 0.7, 0.7)
        this.phoneScene.position.set(-0.125, -0.05, 0)
        this.phoneScene.rotation.y = THREE.MathUtils.degToRad(200)
        this.scene.add(this.phoneScene)
        // this.timeline.fromTo(gltf.scene.position, { x: 0.5 }, { x: 0.25 }, 0);
        // this.timeline.fromTo(
        //   this.phoneScene.rotation,
        //   { y: THREE.MathUtils.degToRad(225) },
        //   { y: THREE.MathUtils.degToRad(135) },
        //   0
        // );
      },
      (xhr) => {
        // console.log(`${xhr.loaded / (xhr.total * 100)}% loaded`);
      },
      (error) => {
        // console.error(error);
      }
    )
  }

  phone2() {
    this.GLTFLoader.load(
      `/wp-content/themes/designbypelling/js/src/models/phone-statement.glb`,
      (gltf) => {
        // console.log(gltf);
        this.phone2Scene = gltf.scene
        this.phone2Scene.scale.set(0.7, 0.7, 0.7)
        this.phone2Scene.position.set(0.125, 0.025, -0.25)
        this.phone2Scene.rotation.y = THREE.MathUtils.degToRad(160)
        this.scene.add(this.phone2Scene)
        // this.timeline.fromTo(gltf.scene.position, { x: 0.5 }, { x: 0.25 }, 0);
        // this.timeline.fromTo(
        //   this.phoneScene.rotation,
        //   { y: THREE.MathUtils.degToRad(225) },
        //   { y: THREE.MathUtils.degToRad(135) },
        //   0
        // );
      },
      (xhr) => {
        // console.log(`${xhr.loaded / (xhr.total * 100)}% loaded`);
      },
      (error) => {
        // console.error(error);
      }
    )
  }

  animate() {
    const elapsedTime = this.clock.getElapsedTime()

    if (this.phoneScene) {
      // this.phoneScene.rotation.y = -0.5 * elapsedTime;
      this.phoneScene.rotation.y = THREE.MathUtils.degToRad(200 + this.mouse.x * 45)
      this.phoneScene.rotation.x = THREE.MathUtils.degToRad(0 - this.mouse.y * 30)
    }

    if (this.phone2Scene) {
      this.phone2Scene.rotation.y = THREE.MathUtils.degToRad(160 + this.mouse.x * 30)
      this.phone2Scene.rotation.x = THREE.MathUtils.degToRad(0 - this.mouse.y * 20)
    }

    this.renderer.render(this.scene, this.camera)
    window.requestAnimationFrame(this.animate.bind(this))
  }

  isLowEndDevice() {
    const navigatorInfo = navigator.userAgent.toLowerCase()
    const lowEndDeviceKeywords = ["android", "mobile"]
    const isMobile = lowEndDeviceKeywords.some((keyword) => navigatorInfo.includes(keyword))

    const hasLimitedMemory = navigator.deviceMemory && navigator.deviceMemory <= 2 // 2GB or less
    const isSlowCPU = navigator.hardwareConcurrency && navigator.hardwareConcurrency <= 2 // 2 cores or less

    // console.table({
    //   isMobile,
    //   hasLimitedMemory,
    //   isSlowCPU,
    // })

    return isMobile || hasLimitedMemory || isSlowCPU
  }
}

export default ThreeHero
