import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class CustomPricing {
  constructor(block) {
    this.block = block
    this.heading = block.querySelector(".custom-pricing__heading")
    this.headingSpan = block.querySelector(".custom-pricing__heading span")
    this.table = block.querySelector(".custom-pricing__table")
    this.tableHead = block.querySelector(".custom-pricing__table-head-container")
    this.gsapInit()
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.headingSpan) {
        this.timeline.fromTo(
          this.headingSpan,
          {
            "--width": "0%",
          },
          {
            "--width": "104%",
            duration: 0.5,
            delay: 0.5,
          },
          0
        )
      }

      if (this.heading) {
        this.timeline.fromTo(
          this.heading,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          },
          0
        )
      }

      if (this.table) {
        this.timeline.fromTo(
          this.table,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0.25,
          },
          0
        )
      }
    }

    if (this.table.classList.contains("custom-pricing__table--sticky")) {
      this.tableTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: this.table,
          start: () => {
            const secondaryHeaderHeight = document.querySelector(".secondary-header")?.offsetHeight ?? 0
            const adminBarHeight = document.querySelector("#wpadminbar")?.offsetHeight ?? 0
            return `top ${secondaryHeaderHeight + adminBarHeight ?? 0}px`
          },
          end: () => {
            return `+=${this.table.offsetHeight - this.tableHead.offsetHeight}`
          },
          scrub: 0,
          pin: this.tableHead,
          pinSpacing: false,
          toggleClass: "custom-pricing__table--active",
          // markers: true,
        },
      })
    }
  }
}

export default CustomPricing
