import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ImageTextParallax {
  constructor(block) {
    this.block = block
    this.headingSpan = block.querySelector(".image-text-parallax__heading span")
    this.slides = block.querySelectorAll(".image-text-parallax__slide")
    this.slideInner = block.querySelector(".image-text-parallax__slide-inner")
    this.currentIndex = 0
    this.gsapInit()
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.headingSpan) {
        this.timeline.to(
          this.headingSpan,
          {
            "--width": "0%",
          },
          {
            "--width": "104%",
            duration: 0.5,
            delay: 0.5,
          },
          0
        )
      }

      if (this.slideInner) {
        this.timeline.fromTo(
          this.slideInner.querySelectorAll(":scope > *"),
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            stagger: 0.25,
          },
          0
        )
      }
    }

    this.slidesTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        pin: true,
        scrub: 0.25,
        refreshPriority: 100 - this.block.dataset.index,
        start: () => {
          const headerHeight = document.querySelector(".secondary-header")?.offsetHeight ?? 0
          const adminBar = document.querySelector("#wpadminbar")?.offsetHeight ?? 0
          return `top ${headerHeight + adminBar}px`
        },
        end: () => `+=${this.slides.length * (this.block.offsetHeight / 2)}`,
      },
    })

    this.slides.forEach((slide, index) => {
      if (index) {
        this.slidesTimeline.fromTo(
          slide,
          {
            xPercent: 100,
          },
          {
            xPercent: 0,
          }
        )
      }
    })

    document.addEventListener("gsapRefresh", () => {
      this.timeline.scrollTrigger.refresh(true)
      // console.log("Timeline refreshed")
    })
  }
}

export default ImageTextParallax
