import datepicker from "js-datepicker"
import intlTelInput from "intl-tel-input"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class CustomForm {
  constructor(block, data) {
    this.block = block
    this.data = data
    this.headingSpan = block.querySelector(".custom-form__heading span")
    this.form = block.querySelector(".custom-form__form")
    this.fileInput = block.querySelector(".custom-form__file")
    this.telFields = block.querySelectorAll(".custom-form__tel")
    this.dateFields = block.querySelectorAll(".custom-form__date")
    this.switchFields = block.querySelectorAll(".custom-form__switch")
    this.sliderFields = block.querySelectorAll(".custom-form__slider")
    this.hiddenFields = block.querySelectorAll(".custom-form__hidden")
    this.selectFields = block.querySelectorAll(".custom-form__select")
    this.customSelects = block.querySelectorAll(".custom-form__custom-select")
    this.sliderTimeout
    this.stages = block.querySelectorAll(".custom-form__stage")
    this.recaptchaField = block.querySelector("#recaptcha_token")
    this.checkboxGroups = block.querySelectorAll(".custom-form__checkbox")
    this.content = block.querySelectorAll(".custom-form__content-wrapper > *")
    this.image = block.querySelector(".custom-form__image")
    this.imageBg = block.querySelector(".custom-form__image-bg")
    this.telSetup(this.telFields)
    this.datePickerSetup(this.dateFields)
    this.setHeight()
    //this.populateRecaptchaField(); removed by Andy as this recaptcha token expires after 2 mins, so populating it in the constructor will mean that users who take longer than 2 mins to populate will get an error, moved the function to be called on form submit
    this.events()
    this.gsapInit()

    // Bind file input event
    if (this.fileInput) {
      this.fileInput.addEventListener("change", () => this.updateFileName())
    }
  }

  setHeight() {
    const stagesHeights = Array.from(this.stages).map((stage) => {
      return stage.offsetHeight
    })

    const height = Math.max(...stagesHeights)
    this.form?.style.setProperty("--height", `${height}px`)

    setTimeout(() => {
      document.dispatchEvent(new Event("gsapRefresh"))
    }, 250)
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.headingSpan) {
        this.timeline.to(
          this.headingSpan,
          {
            "--width": "104%",
            duration: 0.5,
          },
          0
        )
      }

      if (this.content) {
        this.timeline.fromTo(
          this.content,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            stagger: 0.25,
          },
          0
        )
      }

      if (this.image) {
        this.timeline.fromTo(
          this.image,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0.25,
          },
          0
        )
      }

      if (this.imageBg) {
        this.timeline.fromTo(
          this.imageBg,
          {
            "--opacity": "0",
          },
          {
            "--opacity": "1",
            duration: 0.5,
          },
          0
        )

        this.timeline.fromTo(
          this.imageBg,
          {
            "--rotation": "0deg",
          },
          {
            "--rotation": "-43.773deg",
            duration: 0.25,
            delay: 0.5,
          },
          0
        )
      }
    }
  }

  datePickerSetup(fields) {
    fields.forEach((field) => {
      datepicker(field, {
        formatter: (input, date, instance) => {
          input.value = date.toLocaleDateString("en-GB")
        },
        onSelect: (instance, date) => {
          field.dispatchEvent(new CustomEvent("dateselect"))
        },
      })
    })
  }

  //file upload handler, shows names of uploaded files in the upload area
  updateFileName() {
    const input = this.form.querySelector(".custom-form__file")
    const fileNameContainer = this.form.querySelector(".custom-form__file-upload-name")
    if (!input || !fileNameContainer) return // if no attachments

    const files = input.files
    let fileNames = []
    if (files.length > 0) {
      fileNames = Array.from(files).map((file) => file.name)
    }

    fileNameContainer.textContent = fileNames.join(", ") || "No file chosen"
  }

  //check for robert sar spammer
  checkForStrings() {
    const stringsArray = ["robertsar", "robertsarr", "robert sar", "robert sarr"]
    const formFields = this.form.querySelectorAll("input:not([type='checkbox']):not([type='radio']), textarea")

    for (let i = 0; i < formFields.length; i++) {
      const fieldValue = formFields[i].value.toLowerCase() // Convert to lowercase
      for (let j = 0; j < stringsArray.length; j++) {
        if (fieldValue.includes(stringsArray[j].toLowerCase())) {
          // Convert to lowercase
          // Redirect to a new page if a match is found
          window.location.href = "https://www.splitit.com/thank-you/?ref=submitted"
          return true // Indicate that a redirect occurred
        }
      }
    }

    return false
  }

  //populate 6sense hidden fields
  process6SenseHiddenFields() {
    console.log("Processing data from 6sense custom-form.js:", this.data)
    const sixsense_company = document.querySelector('input[name="6s-company"]')
    sixsense_company.value = this.data.company.name
    const sixsense_company_annual_revenue = document.querySelector('input[name="6s-annual-revenue"]')
    sixsense_company_annual_revenue.value = this.data.company.annual_revenue
    const sixsense_company_country = document.querySelector('input[name="6s-country"]')
    sixsense_company_country.value = this.data.company.country
    const sixsense_company_domain = document.querySelector('input[name="6s-website"]')
    sixsense_company_domain.value = this.data.company.domain
    const sixsense_segments_names = document.querySelector('input[name="6s-segments"]')
    sixsense_segments_names.value = this.data.segments.names
  }

  //capture form data to store in a cookie
  storeFormDataInCookie() {
    const formData = {}

    // Collect data from input fields
    this.block.querySelectorAll("input:not([type='checkbox']):not([type='radio']), textarea, select").forEach((input) => {
      const dataName = input.getAttribute("name")
      const dataValue = input.value
      formData[dataName] = dataValue
    })

    // Collect data from fieldsets
    this.block.querySelectorAll("fieldset").forEach((fieldset) => {
      const data = []
      fieldset.querySelectorAll("input").forEach((input) => {
        if (input.checked) {
          data.push(input.value)
        }
      })
      formData[fieldset.dataset.name] = data
    })

    // Store the data in a cookie
    //document.cookie = `formData=${JSON.stringify(formData)}; path=/`;
    document.cookie = `splitit_custom_form_data=${JSON.stringify(formData)}; path=/`
  }

  toggleSelect(select, options, e) {
    const search = select.querySelector(".custom-form__custom-select-search")

    if (e.target != search) {
      select.classList.toggle("custom-form__custom-select--active")
      search.value = ""
      search.focus()
      this.filterOptions(search, options)
    }
  }

  closeSelects(e) {
    this.customSelects.forEach((select) => {
      if (e.target.closest(".custom-form__custom-select") != select) {
        select.classList.remove("custom-form__custom-select--active")
      }
    })
  }

  handleCustomSelectOption(select, options, option, e) {
    const hiddenInput = select.querySelector("input")
    const displayField = select.querySelector(".custom-form__custom-select-display")

    hiddenInput.value = option.dataset.value
    hiddenInput.dispatchEvent(new Event("change"))
    displayField.textContent = option.textContent
    options.forEach((opt) => {
      opt.classList.toggle("custom-form__custom-select-option--selected", opt == option)
    })
  }

  filterOptions(search, options) {
    if (!search.value) {
      options.forEach((option) => {
        option.classList.remove("custom-form__custom-select-option--hidden")
      })

      return
    }

    options.forEach((option) => {
      option.classList.toggle("custom-form__custom-select-option--hidden", !option.textContent.toLowerCase().includes(search.value.toLowerCase()))
    })
  }

  events() {
    window.addEventListener("resize", () => {
      this.setHeight()
    })

    this.customSelects.forEach((select) => {
      const search = select.querySelector(".custom-form__custom-select-search")
      const options = select.querySelectorAll(".custom-form__custom-select-option")

      select.addEventListener("click", (e) => this.toggleSelect(select, options, e))

      options.forEach((option) => {
        option.addEventListener("click", (e) => this.handleCustomSelectOption(select, options, option, e))
      })

      search.addEventListener("keyup", () => this.filterOptions(search, options))
    })

    document.addEventListener("click", (e) => this.closeSelects(e))

    /* //apply classes for 50/50 width fields
    // Search for an element with class name "custom-form__input--fifty-width"
    const fiftyWidthElement = document.querySelector('.custom-form__input--fifty-width');
    
    // Check if the element is found
    if (fiftyWidthElement) {
        // Element found, add class to the custom-form element
        this.block.classList.add('custom-form--has-fifty-width-fields');
    } */

    // Apply classes for 50/50 width fields and add class to the first input element in each pair
    const fiftyWidthElements = document.querySelectorAll(".custom-form__input--fifty-width")

    fiftyWidthElements.forEach((element, index) => {
      // Add class to the first input element in each pair
      if (index % 2 === 0) {
        element.classList.add("custom-form__input--fifty-width-first")
      }

      // Check if the element is found
      if (element) {
        // Element found, add class to the custom-form element
        this.block.classList.add("custom-form--has-fifty-width-fields")
      }
    })

    //Handle hidden fields
    this.hiddenFields.forEach((field) => {
      const dataFrom = field.dataset.from
      const funcName = field.dataset.function

      if (dataFrom && funcName) {
        /* console.log(dataFrom);
        console.log(funcName); */

        const input = this.block.querySelector(`input[name="${dataFrom}"], textarea[name="${dataFrom}"]`)

        input.addEventListener("change", () => {
          //console.log(input.value);

          // const output = hiddenFunction(input.value); // TODO - this function is currently hardcoded, and relies on the function name being set to `hiddenFunction` within WP admin panel
          const output = eval(`${funcName}("${input.value}")`)
          //console.log(output);
          field.value = output
        })
      }
    })

    //Stage switching
    this.stages.forEach((stage) => {
      const backButton = stage.querySelector(".custom-form__back")
      const continueButton = stage.querySelector(".custom-form__continue")
      const inputs = stage.querySelectorAll("input, textarea, select")
      const fieldsets = stage.querySelectorAll("fieldset")
      const customSelects = stage.querySelectorAll(".custom-form__custom-select")
      const button = stage.querySelector(".custom-form__continue, .custom-form__submit")

      //Validate on input change
      const events = ["change", "keyup", "input", "dateselect"]
      inputs.forEach((input) => {
        events.forEach((event) => {
          input.addEventListener(event, (e) => {
            this.validateSingleInput(input)
            // this.validateStage(inputs, fieldsets, customSelects, button)
          })
        })
      })

      //Validate on custom select change
      customSelects.forEach((select) => {
        select.querySelector("input")?.addEventListener("change", (e) => {
          this.validateCustomSelect(select)
          // this.validateStage(inputs, fieldsets, customSelects, button)
        })
      })

      //Add back functionality where back button exists
      if (backButton) {
        backButton.addEventListener("click", () => {
          this.previousStage(stage)
        })
      }

      //Add continue functionality where continue button exists
      if (continueButton) {
        continueButton.addEventListener("click", () => {
          if (this.validateStage(inputs, fieldsets, customSelects, button)) {
            this.nextStage(stage)
          }
        })
      }
    })

    //Set highlight to selected option
    this.switchFields.forEach((field) => {
      const inputs = field.querySelectorAll(".custom-form__switch-input")
      inputs.forEach((input, index) => {
        input.addEventListener("change", (e) => this.setChecked(e, field, index))
      })
    })

    //Handle sliders
    this.sliderFields.forEach((field) => {
      const rangeInput = field.querySelector(".custom-form__slider-range-input")
      const textInput = field.querySelector(".custom-form__slider-text-input")
      const minusBtn = field.querySelector(".custom-form__slider-button--minus")
      const plusBtn = field.querySelector(".custom-form__slider-button--plus")
      const hiddenInput = field.querySelector(".custom-form__slider-hidden-input")
      const prefix = field.dataset.prefix
      const suffix = field.dataset.suffix

      //Update hidden input value
      //amended to only set numeric value as required by Pardot
      textInput.addEventListener("change", () => {
        const inputValue = textInput.value
        //const numericValue = inputValue.match(/\d+/); // Extract numeric part
        const numericValue = inputValue.split(",").join("")
        // console.log(numericValue)
        if (numericValue !== null) {
          hiddenInput.value = numericValue //numericValue[0];
        } else {
          hiddenInput.value = ""
        }
        hiddenInput.dispatchEvent(new Event("change")) // force event as event wasn't being triggered
      })
      rangeInput.addEventListener("change", () => {
        const inputValue = textInput.value
        //const numericValue = inputValue.match(/\d+/); // Extract numeric part
        const numericValue = inputValue.split(",").join("")

        if (numericValue !== null) {
          hiddenInput.value = numericValue //numericValue[0];
        } else {
          hiddenInput.value = ""
        }
        hiddenInput.dispatchEvent(new Event("change")) // force event as event wasn't being triggered
      })

      //Update slider value on change
      rangeInput.addEventListener("input", () => {
        this.updateSliderValue(field, rangeInput, textInput, minusBtn, plusBtn)
      })

      //Slider button functionality
      minusBtn.addEventListener("click", () => {
        this.decreaseSliderValue(field, rangeInput, textInput, minusBtn, plusBtn)
      })
      plusBtn.addEventListener("click", () => {
        this.increaseSliderValue(field, rangeInput, textInput, minusBtn, plusBtn)
      })

      //Typed value handler
      textInput.addEventListener("keyup", () => this.handleTextInput(field, rangeInput, textInput, minusBtn, plusBtn))

      // Populate hidden field with initial value on page load
      this.updateSliderValue(field, rangeInput, textInput, minusBtn, plusBtn)
    })

    //populate recaptcha on submit -  added a timeout as without it, the recaptcha token doesn't get submitted and an empty string is returned
    this.form.addEventListener("submit", (e) => {
      e.preventDefault()

      // Check for strings and redirect if robertsar filled the form in
      if (this.checkForStrings()) {
        // Do not execute the rest of the code if a redirect occurred
        return
      }

      //populate the pardot /6s hidden fields
      this.process6SenseHiddenFields()

      // Store form data in a cookie
      this.storeFormDataInCookie()

      const dataObj = {}
      this.block.querySelectorAll("input:not([type='checkbox']):not([type='radio']), textarea, select").forEach((input) => {
        const dataName = input.getAttribute("name")
        const dataValue = input.value
        dataObj[dataName] = dataValue
      })

      this.block.querySelectorAll("fieldset").forEach((fieldset) => {
        if (fieldset.dataset.type == "checkbox") {
          const data = []
          fieldset.querySelectorAll("input").forEach((input) => {
            if (input.checked) {
              data.push(input.value)
            }
          })
          dataObj[fieldset.dataset.name] = data
        } else {
          dataObj[fieldset.dataset.name] = fieldset.querySelector("input").value
        }
      })

      dataObj.element = "SUBMIT"

      if (typeof dataLayer !== "undefined") {
        dataLayer.push({
          event: "gtm.formSubmit",
          gtm: dataObj,
        })
      }

      this.populateRecaptchaField()

      // console.log(dataObj)

      setTimeout(() => {
        this.form.submit()
      }, 2000)
    })

    this.checkboxGroups.forEach((checkboxGroup) => {
      const checkboxes = checkboxGroup.querySelectorAll("input[type='checkbox']")
      const hidden = checkboxGroup.querySelector(".custom-form__checkbox-string")

      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener("change", () => {
          let stringValue = ""

          checkboxes.forEach((checkbox, index) => {
            if (checkbox.checked) {
              stringValue += checkbox.value + ", "
            }
          })

          hidden.value = stringValue.slice(0, -2)
        })
      })
    })
  }

  async populateRecaptchaField() {
    //Check grecaptcha object exists
    if (!grecaptcha) {
      console.log("Grecaptcha does not exist.")
      return
    }

    grecaptcha.ready(async () => {
      const token = await grecaptcha.execute(recaptchasettings.site_key, {
        action: "submit",
      })
      this.recaptchaField.value = token
    })
  }

  validateSingleInput(input) {
    input.classList.remove("custom-form__invalid")

    if (!input.checkValidity()) {
      input.classList.add("custom-form__invalid")
      return false
    } else {
      return true
    }
  }

  validateCustomSelect(select) {
    const display = select.querySelector(".custom-form__custom-select-display")
    display?.classList.remove("custom-form__invalid")

    const input = select.querySelector("input[type='hidden']")
    if (!input.value) {
      display.classList.add("custom-form__invalid")
      return false
    } else {
      return true
    }
  }

  validateStage(inputs, fieldsets, customSelects, button) {
    const invalidInputs = []
    inputs.forEach((input) => {
      if (!this.validateSingleInput(input)) {
        invalidInputs.push(input)
      }
    })

    //Validate fieldsets
    fieldsets.forEach((fieldset) => {
      //Remove invalid class
      fieldset.classList.remove("custom-form__invalid")

      const boxes = fieldset.querySelectorAll("input")
      let atLeastOneBoxChecked = false
      boxes.forEach((box) => {
        if (box.checked) {
          atLeastOneBoxChecked = true
        }
      })

      if (fieldset.hasAttribute("required") && !atLeastOneBoxChecked) {
        invalidInputs.push(fieldset)
        fieldset.classList.add("custom-form__invalid")
      }
    })

    //Validate custom selects
    customSelects.forEach((select) => {
      if (!this.validateCustomSelect(select)) {
        invalidInputs.push(select)
      }
    })

    //Set button to disabled if stage invalid
    // if (invalidInputs.length && button) {
    //   button?.setAttribute("disabled", "")
    // } else {
    //   button?.removeAttribute("disabled")
    // }

    // console.log(invalidInputs)

    return invalidInputs.length ? false : true
  }

  previousStage(stage) {
    stage.classList.remove("custom-form__stage--activated")

    //Activate previous stage
    const previousStage = this.block.querySelector(`.custom-form__stage--${Number(stage.dataset.stage) - 1}`)
    previousStage.classList.remove("custom-form__stage--deactivated")
    previousStage.classList.add("custom-form__stage--activated")
  }

  nextStage(stage) {
    //Deactivate current stage
    stage.classList.remove("custom-form__stage--activated")
    stage.classList.add("custom-form__stage--deactivated")

    //Activate next stage
    const nextStage = this.block.querySelector(`.custom-form__stage--${Number(stage.dataset.stage) + 1}`)
    nextStage.classList.add("custom-form__stage--activated")
  }

  handleTextInput(field, rangeInput, textInput, minusBtn, plusBtn) {
    const value = textInput.value

    if (value == "") {
      clearTimeout(this.sliderTimeout)
      this.sliderTimeout = setTimeout(() => {
        //Set text and range inputs to valid value
        const valuesArray = field.dataset.values.split(", ")
        const validValue = valuesArray[0]

        textInput.value = validValue
        rangeInput.value = valuesArray[0]
        this.disableButtons(rangeInput, minusBtn, plusBtn)
      }, 500)
    }

    if (value != "") {
      clearTimeout(this.sliderTimeout)
      this.sliderTimeout = setTimeout(() => {
        //Set text and range inputs to valid value
        const valuesArray = field.dataset.values.split(", ")
        const validValue = this.validateInput(textInput.value, valuesArray)

        textInput.value = validValue
        rangeInput.value = valuesArray.indexOf(validValue.toString()) + 1
        textInput.dispatchEvent(new Event("change"))
        this.disableButtons(rangeInput, minusBtn, plusBtn)
      }, 500)
    }
  }

  validateInput(inputValue, rangeValues) {
    //Remove non-numeric characters
    const inputNumber = parseFloat(inputValue)

    //Get range values as array of numbers
    const rangeNumbers = [...rangeValues].reverse().map((val) => {
      return parseFloat(val)
    })

    //Get number in array closest to input number
    const validValue = rangeNumbers.reduce((prev, curr) => {
      return Math.abs(curr - inputNumber) < Math.abs(prev - inputNumber) ? curr : prev
    })

    return validValue
  }

  decreaseSliderValue(field, rangeInput, textInput, minusBtn, plusBtn) {
    rangeInput.value--
    this.updateSliderValue(field, rangeInput, textInput, minusBtn, plusBtn)
  }

  increaseSliderValue(field, rangeInput, textInput, minusBtn, plusBtn) {
    rangeInput.value++
    this.updateSliderValue(field, rangeInput, textInput, minusBtn, plusBtn)
  }

  updateSliderValue(field, rangeInput, textInput, minusBtn, plusBtn) {
    const values = field.dataset.values.split(", ")
    textInput.value = values[rangeInput.value - 1]

    textInput.dispatchEvent(new Event("change"))

    this.disableButtons(rangeInput, minusBtn, plusBtn)
  }

  disableButtons(rangeInput, minusBtn, plusBtn) {
    //Disable buttons at min/max range
    if (rangeInput.value == rangeInput.getAttribute("min")) {
      minusBtn.setAttribute("disabled", "")
    } else {
      minusBtn.removeAttribute("disabled")
    }

    if (rangeInput.value == rangeInput.getAttribute("max")) {
      plusBtn.setAttribute("disabled", "")
    } else {
      plusBtn.removeAttribute("disabled")
    }
  }

  setChecked(e, field, index) {
    field.dataset.checked = index
  }

  telSetup(fields) {
    fields.forEach((field) => {
      intlTelInput(field, {
        hiddenInput: () => ({phone: "full_phone", country: "country_code"}),
        onlyCountries: field.dataset.countries ? field.dataset.countries.split(", ") : [],
        separateDialCode: true,
        initialCountry: "auto",
        geoIpLookup: (callback) => {
          fetch("https://ipapi.co/json")
            .then((res) => res.json())
            .then((data) => callback(data.country_code))
            .catch(() => callback("us"))
        },
        loadUtilsOnInit: () => import("intl-tel-input/utils"),
      })
    })
  }
}

// Add an event listener to call the function when the DOM is fully loaded
document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementById("splitit-form-error")) {
    // Get the query parameters
    const queryParams = getQueryParams()

    // Check if "errorMessage" is present in the query parameters
    if (queryParams.errorMessage) {
      // Scroll to the element with ID "gatedform"
      const element = document.getElementById("splitit-form-error")
      if (element) {
        // Get the height of the fixed header
        const header = document.querySelector(".site-header")
        const headerHeight = header ? header.offsetHeight : 0

        // Calculate the position to scroll to
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
        const offsetPosition = elementPosition - headerHeight

        // Scroll to the calculated position
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        })
      }
    }
  }
})

export default CustomForm
