import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ContentColumnsStatistics {
  constructor(block) {
    this.block = block
    this.heading = block.querySelector(".content-columns-statistics__heading")
    this.headingSpan = block.querySelector(".content-columns-statistics__heading span")
    this.stats = block.querySelectorAll(".content-columns-statistics__stat")
    this.gsapInit()
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.heading) {
        this.timeline.fromTo(
          this.heading,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          },
          0
        )
      }

      if (this.headingSpan) {
        this.timeline.fromTo(
          this.headingSpan,
          {
            "--width": "0%",
          },
          {
            "--width": "104%",
            duration: 0.5,
            delay: 0.25,
          },
          0
        )
      }

      if (this.stats) {
        this.timeline.fromTo(
          this.stats,
          {
            opacity: 0,
            y: 100,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            delay: 0.25,
            stagger: 0.125,
          },
          0
        )
      }
    }
  }
}

export default ContentColumnsStatistics
