import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ContentVideo {
  constructor(block) {
    this.block = block
    this.video = block.querySelector(".content-video__video")
    this.events()
    this.gsapInit()
  }

  events() {
    this.video.addEventListener("click", (e) => this.openVideoModal(e))
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.video) {
        this.timeline.fromTo(
          this.video,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          },
          0
        )
      }
    }
  }

  openVideoModal(e) {
    e.preventDefault()
    this.injectModal(e.target.dataset.type, e.target.dataset.source, e.target.dataset.filetype)
  }

  injectModal(type, source, filetype) {
    let video

    switch (type) {
      case "self":
        video = `<video controls autoplay><source src="${source}" type="${filetype}">Your browser does not support the video tag.</video>`
        break
      case "youtube":
        video = `<iframe src="https://www.youtube.com/embed/${source}?autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>`
        break
      case "vimeo":
        video = `<iframe src="https://player.vimeo.com/video/${source}?autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>`
        break
    }

    const html = `
			<div class="splitit-modal">
				<button class="splitit-modal__close"><i class="fa-solid fa-xmark"></i></button>
				<div class="container">
					<div class="row">
						<div class="col">
							${video}
						</div>
					</div>
				</div>
			</div>
		`

    document.querySelector("body").insertAdjacentHTML("beforeend", html)
    this.modal = document.querySelector(".splitit-modal")
    this.closeButton = this.modal.querySelector(".splitit-modal__close")

    this.closeButton?.addEventListener("click", () => this.removeModal())
    document.addEventListener("keyup", (e) => this.keypressHandler(e))
  }

  keypressHandler(e) {
    if (e.keyCode == 27) {
      this.removeModal()
    }
  }

  removeModal() {
    this.modal.remove()
    document.removeEventListener("keyup", (e) => this.keypressHandler(e))
  }
}

export default ContentVideo
