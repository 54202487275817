import Flickity from "flickity"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ClientLogos {
  constructor(block) {
    this.block = block
    this.items = block.querySelector(".client-logos__items")
    this.gsapInit()
    this.flickityInit()
    this.events()
  }

  events() {
    document.addEventListener("DOMContentLoaded", () => {
      this.flkty.resize()
    })
  }

  flickityInit() {
    this.flkty = new Flickity(this.items, {
      pageDots: false,
      autoPlay: false,
      wrapAround: true,
      prevNextButtons: false,
      adaptiveHeight: true,
      resize: true,
      contain: true,
      imagesLoaded: true,
    })

    //Smooth scroll
    let requestId
    this.flkty.x = 0

    const play = () => {
      // Set the decrement of position x
      this.flkty.x = this.flkty.x - 0.25
      // Settle position into the slider
      this.flkty.settle(this.flkty.x)
      // Set the requestId to the local variable
      requestId = window.requestAnimationFrame(play)
    }

    play()
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 95%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.items) {
        this.timeline.fromTo(
          this.items,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            stagger: 0.125,
          },
          0
        )
      }
    }
  }
}

export default ClientLogos
