import _ from "lodash"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ScrollableTabs {
  constructor(block) {
    this.block = block
    this.container = block.querySelector(".container")
    this.head = block.querySelector(".scrollable-tabs__head")
    this.headingSpan = block.querySelector(".scrollable-tabs__heading span")
    this.navLinks = block.querySelectorAll(".scrollable-tabs__list-item-link")
    this.inner = block.querySelector(".scrollable-tabs__inner")
    this.contentWrapper = block.querySelector(".scrollable-tabs__content-wrapper")
    this.contentOuter = block.querySelector(".scrollable-tabs__content-outer")
    this.contents = block.querySelectorAll(".scrollable-tabs__content")
    this.currentIndex = 0

    this.gsapInit()
    this.events()
  }

  events() {
    this.navLinks.forEach((link) => link.addEventListener("click", (e) => this.navigateTo(e, link.dataset.id)))
    // window.addEventListener("resize", () => console.log(this.contentOuter.offsetHeight))
  }

  navigateTo(e, index) {
    e.preventDefault()

    const blockTop = this.block.closest(".pin-spacer")?.offsetTop ?? 0
    const innerOffset = window.innerHeight >= 900 ? 0 : this.inner.offsetTop + 30
    const secondaryHeaderHeight = document.querySelector(".secondary-header")?.offsetHeight ?? 0
    const scrollPos = blockTop + innerOffset - secondaryHeaderHeight + (this.contentOuter.offsetHeight / this.contents.length) * index

    window.scrollTo({
      left: 0,
      top: scrollPos,
      behavior: "instant",
    })
  }

  gsapInit() {
    const mm = gsap.matchMedia()

    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.headingSpan) {
        this.timeline.fromTo(
          this.headingSpan,
          {
            "--width": "0%",
          },
          {
            "--width": "104%",
            duration: 0.5,
            delay: 0.5,
          },
          0
        )
      }

      if (this.head) {
        this.timeline.fromTo(
          [...this.head.querySelectorAll(":scope > *"), this.inner],
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            stagger: 0.25,
          },
          0
        )
      }
    }

    mm.add(
      {
        isSmall: "(max-height: 899px)",
        isLarge: "(min-height: 900px)",
      },
      (context) => {
        const {isSmall, isLarge} = context.conditions

        this.innerTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: isSmall ? this.inner : this.block,
            start: () => {
              const secondaryHeaderHeight = document.querySelector(".secondary-header")?.offsetHeight ?? 0
              const adminBarHeight = document.querySelector("#wpadminbar")?.offsetHeight ?? 0
              return isSmall ? `top ${secondaryHeaderHeight + adminBarHeight + 20 ?? 20}px` : `top ${secondaryHeaderHeight + adminBarHeight ?? 0}px`
            },
            end: () => `+=${this.contentOuter.offsetHeight * ((this.contents.length - 1) / this.contents.length)}`,
            invalidateOnRefresh: true,
            pin: this.block,
            // anticipatePin: 1,
            scrub: true,
            refreshPriority: 100 - this.block.dataset.index,
            // markers: true,
            onUpdate: (self) => {
              this.currentIndex = Math.min(Math.max(Math.ceil(self.progress * this.contents.length - 1), 0), this.contents.length - 1)

              if (this.currentIndex != this.previousIndex) {
                this.navLinks.forEach((link) => {
                  link.classList.toggle("scrollable-tabs__list-item-link--active", link.dataset.id == this.currentIndex)
                })

                this.previousIndex = this.currentIndex
              }
            },
          },
        })

        this.innerTimeline.fromTo(
          this.contentOuter,
          {
            y: () => 0,
          },
          {
            y: () => `-=${this.contentOuter.offsetHeight * ((this.contents.length - 1) / this.contents.length)}`,
            ease: `steps(${this.contents.length - 1})`,
          }
        )

        document.addEventListener("gsapRefresh", () => {
          this.innerTimeline.scrollTrigger.refresh(true)
          // console.log("Timeline refreshed")
        })
      }
    )
  }
}

export default ScrollableTabs
