class SenseShortcode {
  constructor(el, data) {
    this.el = el
    this.data = data
    this.process6s(data)
  }

  flattenObject(obj) {
    const flattened = {}

    Object.keys(obj).forEach((key) => {
      const value = obj[key]

      if (typeof value === "object" && value !== null && !Array.isArray(value)) {
        Object.assign(flattened, this.flattenObject(value))
      } else {
        flattened[key] = value
      }
    })

    return flattened
  }

  process6s(data) {
    //console.log("Processing data for 6sense shortcodes:", data)

    const [key, value] = Object.entries(this.flattenObject(data)).find(([k, v]) => k == this.el.dataset.key)

    if (!key) {
      console.log("Key could not be found in 6sense data.")
    }

    if (!value) {
      console.log("6sense data does not contain a value for this key.")
    }

    if (this.el.tagName.toLowerCase() == "input") {
      this.el.value = value
    } else {
      this.el.textContent = value
    }
  }
}

export default SenseShortcode
