import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class StaticQuote {
  constructor(block) {
    this.block = block
    this.content = block.querySelectorAll(".static-quote__inner > *")
    this.gsapInit()
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.content) {
        this.timeline.fromTo(
          this.content,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            stagger: 0.25,
          },
          0
        )
      }
    }
  }
}

export default StaticQuote
