import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ParallaxPhone {
  constructor(block) {
    this.block = block
    this.headingSpan = block.querySelector(".parallax-phone__heading span")
    this.inner = block.querySelector(".parallax-phone__inner")
    this.contentsOuter = block.querySelector(".parallax-phone__contents")
    this.contentsInner = block.querySelector(".parallax-phone__contents-inner")
    this.contents = block.querySelectorAll(".parallax-phone__content")
    this.svgContentGroups = Array.from(block.querySelectorAll(".parallax-phone__svg-content-group")).reverse()
    this.svgAnimationGroups = Array.from(block.querySelectorAll(".parallax-phone__svg-animation-group")).reverse()
    this.previousIndex
    this.currentIndex = 0

    this.gsapInit()
  }

  gsapInit() {
    const mm = gsap.matchMedia()

    mm.add(
      {
        isSmall: "(max-height: 1079px)",
        isLarge: "(min-height: 1080px)",
      },
      (context) => {
        const {isSmall, isLarge} = context.conditions

        this.blockTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: this.block,
            start: () => "top 75%",
            toggleActions: "play none none reverse",
          },
          ease: "power2.inOut",
        })

        if (this.block.dataset.animations) {
          if (this.headingSpan) {
            this.blockTimeline.fromTo(
              this.headingSpan,
              {
                "--width": "0%",
              },
              {
                "--width": "104%",
                duration: 0.5,
                delay: 0.5,
              },
              0
            )
          }

          if (this.inner) {
            this.blockTimeline.fromTo(
              this.inner.querySelectorAll(":scope > *"),
              {
                opacity: 0,
                y: 50,
              },
              {
                opacity: 1,
                y: 0,
                duration: 0.5,
                stagger: 0.25,
              },
              0
            )
          }
        }

        this.svgAnimationGroups.forEach((group) => {
          gsap.set(group, {
            transformOrigin: "center",
          })

          if (group.dataset.index == 1) {
            gsap.set(group, {
              y: 0,
            })
          } else {
            gsap.set(group, {
              y: 1550,
            })
          }
        })

        this.timeline = gsap.timeline({
          scrollTrigger: {
            trigger: isSmall ? this.contentsOuter : this.block,
            start: () => {
              const secondaryHeaderHeight = document.querySelector(".secondary-header")?.offsetHeight ?? 0
              const adminBarHeight = document.querySelector("#wpadminbar")?.offsetHeight ?? 0
              return isSmall ? "bottom bottom" : `top ${secondaryHeaderHeight + adminBarHeight ?? 0}px`
            },
            end: () => `+=${this.contentsInner?.offsetHeight * 2}px`,
            onUpdate: (self) => {
              this.currentIndex = Math.min(Math.max(Math.round(self.progress * (this.contents.length + 1)), 0), this.contents.length + 1)
              // console.log(this.currentIndex)

              if (this.currentIndex != this.previousIndex) {
                //Update active content section
                this.contents.forEach((content) => {
                  content.classList.toggle("parallax-phone__content--active", content.dataset.index == this.currentIndex)
                })

                //Update active SVG content group
                this.svgContentGroups.forEach((group) => {
                  if (group.dataset.index < this.currentIndex && group.dataset.index < this.contents.length) {
                    gsap.to(group, {
                      y: -1550,
                    })
                  } else if (group.dataset.index > this.currentIndex) {
                    if (group.dataset.index != 1) {
                      gsap.to(group, {
                        y: 1550,
                      })
                    }
                  } else if (group.dataset.index == this.currentIndex) {
                    gsap.to(group, {
                      y: 0,
                    })
                  }
                })

                this.svgAnimationGroups.forEach((group) => {
                  if (this.currentIndex == 0) {
                    if (group.dataset.index == 1) {
                      gsap.to(group, {
                        y: 0,
                      })
                    } else {
                      gsap.to(group, {
                        y: 1550,
                      })
                    }

                    gsap.fromTo(
                      group,
                      {
                        scale: 1.5,
                        "--fillColor": "#FFFFFF",
                        "--strokeColor": "#0B8286",
                      },
                      {
                        scale: 1,
                        "--fillColor": "transparent",
                        "--strokeColor": "transparent",
                        delay: 0.25,
                      }
                    )
                  } else {
                    if (group.dataset.index < this.currentIndex) {
                      if (group.dataset.index < this.contents.length) {
                        gsap.to(group, {
                          y: -1550,
                        })
                      }
                      gsap.fromTo(
                        group,
                        {
                          scale: 1.5,
                          "--fillColor": "#FFFFFF",
                          "--strokeColor": "#0B8286",
                        },
                        {
                          scale: 1,
                          "--fillColor": "transparent",
                          "--strokeColor": "transparent",
                          delay: 0.25,
                        }
                      )
                    } else if (group.dataset.index > this.currentIndex) {
                      gsap.to(group, {
                        y: 1550,
                      })

                      gsap.fromTo(
                        group,
                        {
                          scale: 1.5,
                          "--fillColor": "#FFFFFF",
                          "--strokeColor": "#0B8286",
                        },
                        {
                          scale: 1,
                          "--fillColor": "transparent",
                          "--strokeColor": "transparent",
                          delay: 0.25,
                        }
                      )
                    } else if (group.dataset.index == this.currentIndex) {
                      gsap.to(group, {
                        y: 0,
                      })
                      gsap.fromTo(
                        group,
                        {
                          scale: 1,
                          "--fillColor": "transparent",
                          "--strokeColor": "transparent",
                        },
                        {
                          scale: 1.5,
                          "--fillColor": "#FFFFFF",
                          "--strokeColor": "#0B8286",
                          delay: 0.25,
                        }
                      )
                    }
                  }
                })

                this.previousIndex = this.currentIndex
              }
            },
            toggleActions: "play none none reverse",
            refreshPriority: 100 - this.block.dataset.index,
            invalidateOnRefresh: true,
            scrub: 0.25,
            pin: this.block,
          },
          duration: this.contents.length,
        })

        //Set initial index
        this.currentIndex = Math.min(Math.max(Math.round(this.timeline.progress * (this.contents.length + 1)), 0), this.contents.length + 1)

        this.timeline.fromTo(
          this.contentsInner,
          {
            y: () => `${this.contentsInner.offsetHeight / this.contents.length}`,
          },
          {
            y: () => `-=${this.contentsInner.offsetHeight + this.contentsInner.offsetHeight / this.contents.length}`,
            duration: this.contents.length,
            ease: "linear",
          },
          0
        )

        document.addEventListener("gsapRefresh", () => {
          this.timeline.scrollTrigger.refresh(true)
        })
      }
    )
  }
}

export default ParallaxPhone
