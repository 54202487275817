import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class Report {
  constructor(block, data) {
    this.block = block
    this.data = data
    this.headingSpan = block.querySelector(".report__heading span")
    this.innerRight = block.querySelector(".report__inner-right")
    this.content = block.querySelectorAll(".report__inner-left > *")
    this.image = block.querySelector(".report__image")
    this.form = block.querySelector(".report__form")
    this.message = block.querySelector(".report__form-message")
    this.recaptchaField = block.querySelector("#recaptcha_token")
    this.gsapInit()
    this.events()
  }

  events() {
    document.addEventListener("DOMContentLoaded", () => {
      if (this.form) {
        // this.populateGatedHiddenFields()
        this.processGated6SenseHiddenFields()
      }

      const queryParams = this.getQueryParams()

      setTimeout(() => {
        if (queryParams.errorMessage) {
          if (this.message) {
            const header = document.querySelector(".secondary-header")
            const headerHeight = header ? header.offsetHeight : 0

            // Calculate the position to scroll to
            const elementPosition = this.block.offsetTop
            const offsetPosition = elementPosition - headerHeight

            // Scroll to the calculated position
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            })
          }
        }
      }, 1000)
    })

    this.form.addEventListener("submit", (e) => {
      e.preventDefault()
      this.populateRecaptchaField()

      setTimeout(() => {
        this.form.submit()
      }, 2000)
    })
  }

  async populateRecaptchaField() {
    //Check grecaptcha object exists
    if (!grecaptcha) {
      console.log("Grecaptcha does not exist.")
      return
    }

    grecaptcha.ready(async () => {
      const token = await grecaptcha.execute(recaptchasettings.site_key, {
        action: "submit",
      })
      this.recaptchaField.value = token
    })
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      gsap.set(this.image, {
        transformOrigin: "bottom left",
      })

      if (this.headingSpan) {
        this.timeline.fromTo(
          this.headingSpan,
          {
            "--width": "0%",
          },
          {
            "--width": "104%",
            duration: 0.5,
            delay: 0.5,
          },
          0
        )
      }

      if (this.innerRight) {
        this.timeline.fromTo(
          this.innerRight,
          {
            "--opacity": "0",
          },
          {
            "--opacity": "1",
            duration: 0.5,
          },
          0
        )

        this.timeline.fromTo(
          this.innerRight,
          {
            "--rotation": "0deg",
          },
          {
            "--rotation": "-32deg",
            duration: 0.25,
            delay: 0.5,
          },
          0
        )
      }

      if (this.image) {
        this.timeline.fromTo(
          this.image,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0.25,
          },
          0
        )
      }

      if (this.content) {
        this.timeline.fromTo(
          this.content,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            stagger: 0.25,
          },
          0
        )
      }
    }
  }

  processGated6SenseHiddenFields() {
    // console.log("Processing data from 6sense gated-standalone-form:", data)
    const sixsense_company = document.querySelector('.report__form input[name="6s-company"]')
    sixsense_company.value = this.data.company.name
    const sixsense_company_annual_revenue = document.querySelector('.report__form input[name="6s-annual-revenue"]')
    sixsense_company_annual_revenue.value = this.data.company.annual_revenue
    const sixsense_company_country = document.querySelector('.report__form input[name="6s-country"]')
    sixsense_company_country.value = this.data.company.country
    const sixsense_company_domain = document.querySelector('.report__form input[name="6s-website"]')
    sixsense_company_domain.value = this.data.company.domain
    const sixsense_segments_names = document.querySelector('.report__form input[name="6s-segments"]')
    sixsense_segments_names.value = this.data.segments.names
  }

  getQueryParams() {
    const params = {}
    const queryString = window.location.search.substring(1)
    const regex = /([^&=]+)=([^&]*)/g
    let match
    while ((match = regex.exec(queryString))) {
      params[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
    }
    return params
  }
}

export default Report
