import Flickity from "flickity"

class HoverCarousel {
  constructor(block) {
    this.block = block
    this.items = block.querySelector(".hover-carousel__items")
    this.flickityInit()
  }

  flickityInit() {
    this.flkty = new Flickity(this.items, {
      pageDots: false,
      autoPlay: false,
      wrapAround: true,
      prevNextButtons: false,
      adaptiveHeight: true,
      resize: true,
      contain: true,
    })

    //Smooth scroll
    let requestId
    this.flkty.x = 0

    const play = () => {
      // Set the decrement of position x
      this.flkty.x = this.flkty.x - 0.25
      // Settle position into the slider
      this.flkty.settle(this.flkty.x)
      // Set the requestId to the local variable
      requestId = window.requestAnimationFrame(play)
    }

    play()
  }
}

export default HoverCarousel
