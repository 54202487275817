// import * as THREE from "three"
import Globe from "globe.gl"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class AnimatedGlobe {
  constructor(block) {
    this.block = block
    this.leftInner = block.querySelector(".globe__left-inner")
    this.logos = block.querySelectorAll(".globe__left svg > g")

    // this.threeInit()
    // this.animate()
    this.globeInit()
    this.gsapInit()
    this.events()
  }

  events() {
    window.addEventListener("resize", () => {
      this.globe.width(this.block.querySelector(".globe__left-inner").offsetWidth)
      this.globe.height(this.block.querySelector(".globe__left-inner").offsetHeight)
    })
  }

  globeInit() {
    this.globe = Globe()
    this.clock = new THREE.Clock()

    const arcsData = [...Array(10).keys()].map(() => ({
      startLat: (Math.random() - 0.5) * 360,
      startLng: (Math.random() - 0.5) * 360,
      endLat: (Math.random() - 0.5) * 360,
      endLng: (Math.random() - 0.5) * 360,
      color: ["#0b8286", "#a2cd3a "],
    }))

    this.globe(this.leftInner)
      .globeImageUrl("/wp-content/themes/sage/assets/images/content/earth-map.jpg")
      .width(this.leftInner.offsetWidth)
      .height(this.leftInner.offsetHeight)
      .backgroundColor("rgba(255, 255, 255, 0)")
      .showAtmosphere(false)
      .arcsData(arcsData)
      .arcColor("color")
      .arcDashLength(() => Math.random() + 0.5)
      .arcDashGap(() => Math.random() + 0.5)
      .arcDashAnimateTime(() => Math.random() * 4000 + 500)

    this.globe.controls().enabled = false
    this.globe.controls().autoRotate = true
    this.globe.controls().autoRotateSpeed = 0.5

    const directionalLight = this.globe.lights().find((light) => light.type === "DirectionalLight")
    const ambientLight = this.globe.lights().find((light) => light.type === "AmbientLight")
    directionalLight.position.set(0, 0, 0)
    ambientLight.intensity = 6
  }

  gsapInit() {
    this.logos.forEach((logo) => {
      gsap.set(logo, {transformOrigin: "center center"})
    })

    gsap.from(this.logos, {
      scrollTrigger: {
        trigger: this.block,
        start: "top bottom",
        end: "bottom top",
        toggleActions: "restart reset restart reset",
      },
      scale: 0,
      opacity: 0,
      delay: 1,
      duration: 0.5,
      stagger: 0.125,
    })
  }
}

export default AnimatedGlobe
