import throttle from "lodash/throttle"
import debounce from "lodash/debounce"

class SecondaryHeader {
  constructor(header) {
    this.header = header
    this.pageSections = document.querySelectorAll("section")
    this.secondaryHeaderBottomLinks = header.querySelectorAll(".secondary-header__bottom-list-item-link")
    this.onPageTopAnchor = header.querySelector(".secondary-header__top-title")

    this.setSecondaryHeaderHeight()
    this.events()
  }

  events() {
    window.addEventListener(
      "scroll",
      throttle(() => this.handleScroll(), 125)
    )

    window.addEventListener(
      "resize",
      debounce(() => {
        this.setSecondaryHeaderHeight()
      }, 250)
    )

    this.secondaryHeaderBottomLinks.forEach((link) => {
      link.addEventListener("click", (e) => this.scrollToSection(e, link))
    })

    this.onPageTopAnchor.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    })
  }

  setSecondaryHeaderHeight() {
    document.querySelector("body").style.setProperty("--secondary-header-height", `${this.header.offsetHeight}px`)
  }

  handleScroll() {
    this.header.classList.toggle("secondary-header--active", window.scrollY > window.innerHeight / 2)
    this.setCurrentSection()
  }

  scrollToSection(e, link) {
    e.preventDefault()

    let targetElement = document.querySelector(`#${link.dataset.target}`)

    if (targetElement.closest(".pin-spacer")) {
      targetElement = targetElement.closest(".pin-spacer")
    }

    const wpbar = document.querySelector("#wpadminbar")
    const wpbarHeight = wpbar ? wpbar.offsetHeight : 0

    window.scrollTo({
      top: targetElement.getBoundingClientRect().top + window.scrollY - this.header.offsetHeight - wpbarHeight,
      behavior: "smooth",
    })
  }

  setCurrentSection() {
    // const position = window.innerHeight / 2
    const position = this.header.getBoundingClientRect().bottom

    this.pageSections.forEach((section) => {
      const {top, bottom} = section.getBoundingClientRect()

      if (position >= top && position < bottom) {
        this.secondaryHeaderBottomLinks.forEach((link) => {
          link.classList.toggle("secondary-header__bottom-list-item-link--active", link.dataset.target == section.id)

          if (link.dataset.target == section.id) {
            link.scrollIntoView({behavior: "smooth", block: "nearest", inline: "center"})
          }
        })
      }
    })
  }
}

export default SecondaryHeader
