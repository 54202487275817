import Flickity from "flickity"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class CarouselQuote {
  constructor(block) {
    this.block = block
    this.slides = block.querySelector(".carousel-quote__slides")
    this.slidesArray = block.querySelectorAll(".carousel-quote__slide")
    this.image = block.querySelector(".carousel-quote__image")
    this.imageBg = block.querySelector(".carousel-quote__image-bg")
    this.flickityInit()
    this.gsapInit()
  }

  flickityInit() {
    this.flkty = new Flickity(this.slides, {
      pageDots: this.slidesArray.length > 1,
      prevNextButtons: false,
      wrapAround: true,
      autoPlay: 5000,
      // adaptiveHeight: true,
      on: {
        ready: () => {
          document.dispatchEvent(new Event("gsapRefresh"))
        },
      },
    })
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.slides) {
        this.slidesArray.forEach((slide) => {
          this.timeline.fromTo(
            slide.querySelectorAll(":scope > *"),
            {
              opacity: 0,
              y: 50,
            },
            {
              opacity: 1,
              y: 0,
              duration: 0.5,
              stagger: 0.25,
            },
            0
          )
        })
      }

      if (this.image) {
        this.timeline.fromTo(
          this.image,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0.25,
          },
          0
        )
      }

      if (this.imageBg) {
        this.timeline.fromTo(
          this.imageBg,
          {
            "--opacity": "0",
          },
          {
            "--opacity": "0.5",
            duration: 0.5,
          },
          0
        )

        this.timeline.fromTo(
          this.imageBg,
          {
            "--rotation": "0deg",
          },
          {
            "--rotation": "43.773deg",
            duration: 0.25,
            delay: 0.5,
          },
          0
        )
      }
    }
  }
}

export default CarouselQuote
