import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class CollageHero {
  constructor(block) {
    this.block = block
    this.content = block.querySelectorAll(".collage-hero__heading, .collage-hero__content, .collage-hero__ctas")
    this.imagesContainer = block.querySelector(".collage-hero__images")
    this.images = block.querySelectorAll(".collage-hero__image")
    this.gsapInit()
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    this.imagesTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.imagesContainer,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.content) {
        this.timeline.fromTo(
          this.content,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          },
          0
        )
      }

      if (this.images) {
        this.imagesTimeline.from(
          this.images[2],
          {
            y: "+=50",
            opacity: 0,
            duration: 0.5,
            delay: 0,
          },
          0
        )

        this.imagesTimeline.from(
          [this.images[1], this.images[3]],
          {
            y: "+=50",
            opacity: 0,
            duration: 0.5,
            delay: 0.25,
          },
          0
        )

        this.imagesTimeline.from(
          [this.images[0], this.images[4]],
          {
            y: "+=50",
            opacity: 0,
            duration: 0.5,
            delay: 0.5,
          },
          0
        )

        this.imagesTimeline.from(
          this.images[6],
          {
            y: "+=50",
            opacity: 0,
            duration: 0.5,
            delay: 0.75,
          },
          0
        )

        this.imagesTimeline.from(
          [this.images[5], this.images[7]],
          {
            y: "+=50",
            opacity: 0,
            duration: 0.5,
            delay: 1,
          },
          0
        )
      }
    }
  }
}

export default CollageHero
