import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ContentOnPageImage {
  constructor(block) {
    this.block = block
    this.animationContent = block.querySelector(".content-on-page-image__image")
    this.gsapInit()
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.animationContent) {
        this.timeline.fromTo(
          this.animationContent,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          },
          0
        )
      }
    }
  }
}

export default ContentOnPageImage
