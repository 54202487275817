import debounce from "lodash/debounce"

class Footer {
  constructor(footer) {
    this.footer = footer
    this.returnToTop = footer.querySelector(".footer__return")
    this.setFooterHeight()
    this.events()
  }

  events() {
    this.returnToTop.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    })

    window.addEventListener(
      "resize",
      debounce(() => {
        this.setFooterHeight()
      }, 250)
    )
  }

  setFooterHeight() {
    document.querySelector("body").style.setProperty("--footer-height", `${this.footer.offsetHeight}px`)
  }
}

export default Footer
