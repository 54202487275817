import debounce from "lodash/debounce"

class Header {
  constructor(header) {
    this.header = header
    this.navLinks = header.querySelectorAll(".header__nav-list-item-link--has-children")
    this.dropdowns = header.querySelectorAll(".header__dropdown")
    this.cta = header.querySelector(".header__cta-bar")
    this.closeCta = header.querySelector(".header__close-cta")
    this.mobileMenu = header.querySelector(".header__mobile-menu")
    this.mobileMenuButton = header.querySelector(".header__mobile-menu-button")
    this.mobileSubmenus = header.querySelectorAll(".header__mobile-menu-submenu")
    this.mobileSubmenuButtons = header.querySelectorAll(".header__mobile-menu-list-item-link, .header__mobile-menu-submenu-list-item-link")
    this.mobileSubmenuBacks = header.querySelectorAll(".header__mobile-menu-submenu-back")

    this.mobileMenuOpen = false
    this.dropdownOpen = false

    this.setHeaderHeight()
    this.setSingleColumnDropdownPositions()
    // this.checkFirstBlock()
    this.events()
  }

  events() {
    this.navLinks.forEach((link) => {
      link.addEventListener("click", (e) => this.toggleDropdown(e, link))
    })

    this.dropdowns.forEach((dropdown) => {
      dropdown.querySelectorAll(".header__dropdown-list-item").forEach((item) => {
        item.addEventListener("mouseenter", () => {
          this.openDropdownContent(item, dropdown)
        })
      })
    })

    this.closeCta?.addEventListener("click", () => this.handleCloseCta())

    this.mobileMenuButton.addEventListener("click", () => this.toggleMobileMenu())

    this.mobileSubmenuButtons.forEach((button) => {
      button.addEventListener("click", () => this.openSubmenu(button.dataset.id))
    })

    this.mobileSubmenuBacks.forEach((button) => {
      button.addEventListener("click", () => {
        this.closeSubmenu(button.dataset.id)
      })
    })

    document.addEventListener("click", (e) => {
      if (this.dropdownOpen) {
        if (!e.target.closest(".header__dropdown") && !e.target.closest(".header__nav-list-item-link")) {
          this.closeDropdown()
        }
      }
    })

    window.addEventListener(
      "resize",
      debounce(() => {
        this.setHeaderHeight()
        this.setSingleColumnDropdownPositions()
      }, 250)
    )
  }

  setSingleColumnDropdownPositions() {
    this.navLinks.forEach((link) => {
      const dropdown = link.closest(".header__nav-list-item")?.querySelector(".header__dropdown--single")

      if (dropdown) {
        const containerWidth = this.header.querySelector(".container > .row > .col").offsetWidth
        const pageWidth = window.innerWidth
        const pageGutter = (pageWidth - containerWidth) / 2
        const linkPosition = link.getBoundingClientRect().left - pageGutter
        let dropdownPosition = 0

        if (linkPosition > containerWidth * 0.33 && linkPosition <= containerWidth * 0.67) {
          dropdownPosition = containerWidth * 0.33
        } else if (linkPosition > containerWidth * 0.67) {
          dropdownPosition = containerWidth * 0.67
        }

        dropdown.style.setProperty("--left-position", `${dropdownPosition}px`)
      }
    })
  }

  openSubmenu(id) {
    this.mobileSubmenus.forEach((submenu) => {
      if (id == submenu.dataset.id) {
        submenu.classList.add("header__mobile-menu-submenu--active")
      }
    })
  }

  closeSubmenu(id) {
    this.mobileSubmenus.forEach((submenu) => {
      if (id == submenu.dataset.id) {
        submenu.classList.remove("header__mobile-menu-submenu--active")
      }
    })
  }

  toggleMobileMenu() {
    window.scroll(0, 0)
    this.mobileMenuOpen = !this.mobileMenuOpen

    this.mobileMenuButton.classList.toggle("header__mobile-menu-button--active", this.mobileMenuOpen)
    this.mobileMenu.classList.toggle("header__mobile-menu--active", this.mobileMenuOpen)
    this.mobileSubmenus.forEach((submenu) => {
      if (!this.mobileMenuOpen) {
        submenu.classList.remove("header__mobile-menu-submenu--active")
      }
    })
  }

  handleCloseCta() {
    this.cta.classList.add("header__cta-bar--closing")

    setTimeout(() => {
      this.cta.remove()
      this.setHeaderHeight()
    }, 250)
  }

  checkFirstBlock() {
    const firstBlock = document.querySelector(".main-content section")

    if (!firstBlock?.classList.contains("hero-v2") && !firstBlock?.classList.contains("hero")) {
      this.header.classList.add("header--active")
    }
  }

  setHeaderHeight() {
    document.querySelector("body").style.setProperty("--header-height", `${this.header.offsetHeight}px`)
  }

  closeDropdown() {
    this.navLinks.forEach((el) => {
      el.closest(".header__nav-list-item").classList.remove("header__nav-list-item--active")
    })

    this.dropdownOpen = false
  }

  openDropdownContent(item, dropdown) {
    dropdown.querySelectorAll(".header__dropdown-list-item").forEach((el) => {
      el.classList.toggle("header__dropdown-list-item--active", item.dataset.id == el.dataset.id)
    })

    dropdown.querySelectorAll(".header__dropdown-content").forEach((content) => {
      content.classList.toggle("header__dropdown-content--active", content.dataset.id == item.dataset.id)
    })
  }

  toggleDropdown(e, link) {
    e.preventDefault()

    this.navLinks.forEach((el) => {
      if (el == link) {
        el.closest(".header__nav-list-item")?.classList.toggle("header__nav-list-item--active")
      } else {
        el.closest(".header__nav-list-item")?.classList.remove("header__nav-list-item--active")
      }
    })

    this.dropdownOpen = !this.dropdownOpen
  }
}

export default Header
