import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ContentHero {
  constructor(block) {
    this.block = block
    this.headingSpan = block.querySelector(".content-hero__heading span")
    this.cards = block.querySelectorAll(".content-hero__animated-card")
    this.left = block.querySelectorAll(".content-hero__eyebrow, .content-hero__heading, .content-hero__content, .content-hero__ctas, .content-hero__statistic-wrapper, .content-hero__quote-wrapper")
    this.right = block.querySelector(".content-hero__right")
    this.image = block.querySelector(".content-hero__image")
    this.collageImages = block.querySelectorAll(".content-hero__collage-image")
    this.gsapInit()
  }

  gsapInit() {
    const mm = gsap.matchMedia()

    mm.add(
      {
        isSmall: "(max-width: 899px)",
        isLarge: "(min-width: 900px)",
      },
      (context) => {
        const {isSmall, isLarge} = context.conditions

        this.timeline = gsap.timeline({
          scrollTrigger: {
            trigger: this.block,
            start: () => "top 75%",
            toggleActions: "play none none reverse",
          },
          ease: "power2.inOut",
        })

        if (this.block.dataset.animations) {
          if (this.headingSpan) {
            this.timeline.fromTo(
              this.headingSpan,
              {
                "--width": "0%",
              },
              {
                "--width": "104%",
                duration: 0.5,
                delay: 0.5,
              },
              0
            )
          }

          if (this.left) {
            this.timeline.fromTo(
              this.left,
              {
                opacity: 0,
                y: 50,
              },
              {
                opacity: 1,
                y: 0,
                duration: 0.5,
                stagger: 0.25,
              },
              0
            )
          }

          if (this.image) {
            this.timeline.fromTo(
              this.image,
              {
                opacity: 0,
              },
              {
                opacity: 1,
                duration: 0.5,
                delay: 0.25,
              },
              0
            )
          }

          if (this.right) {
            this.timeline.fromTo(
              this.right,
              {
                "--opacity": "0",
              },
              {
                "--opacity": "1",
                duration: 0.5,
                delay: 0.5,
              },
              0
            )

            this.timeline.fromTo(
              this.right,
              {
                "--rotation": "0deg",
              },
              {
                "--rotation": "-32deg",
                duration: 0.25,
                delay: 0.5,
              },
              0
            )
          }
        }

        if (this.cards.length) {
          this.timeline.from(
            this.cards,
            {
              x: () => (isLarge ? "10%" : "50%"),
              y: () => (isLarge ? "-50%" : "-10%"),
              opacity: 0,
              stagger: 0.125,
              delay: 0.125,
            },
            0
          )
        }

        if (this.collageImages.length) {
          this.timeline.fromTo(
            this.collageImages,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              stagger: 0.125,
              delay: 0.125,
            },
            0
          )
        }
      }
    )
  }
}

export default ContentHero
