class PersonalisedBlock {
  constructor(block, data) {
    this.block = block
    this.data = data
    this.query_string = this.getQueryString()
    this.splitit_test = this.getQueryParamValue("splitit_test", this.query_string)
    this.object_testing_mode = this.getQueryParamValue("splitit_object_testing_mode", this.query_string)
    this.keys = {
      parent: "",
      child: "",
    }
    this.test_object = {
      company: {
        domain: "designbypelling.co.uk",
        name: "PELLING MARKETING BY DESIGN UK LTD",
        region: "Northern Europe",
        country: "United Kingdom",
        state: "England",
        city: "Farnham",
        industry: "Business Services",
        country_iso_code: "GB",
        address: "THE PIGGERY, KILN HOUSE STUDIO, BADSHOT FARM LANE, BADSHOT LEA",
        zip: "",
        phone: "+44 1252 341730",
        employee_range: "0 - 9",
        revenue_range: "$1 - $1M",
        employee_count: "8",
        annual_revenue: "720820",
        is_blacklisted: false,
        state_code: "ENG",
        is_6qa: false,
        geoIP_country: "United Kingdom",
        geoIP_state: "Wales",
        geoIP_city: "Cardiff",
        company_match: "Match",
        additional_comment: "Company name or domain match was found",
        industry_v2: [
          {
            industry: "Business Services",
            subindustry: "",
          },
        ],
        naics_description: "Specialized Design Services",
        naics: "5414",
        sic_description: "",
        sic: "",
      },
      scores: [],
      segments: {
        ids: [575957],
        names: ["Personalisation Testing Segment"],
        list: [
          {
            name: "Personalisation Testing Segment",
            id: 575957,
          },
        ],
      },
      confidence: "High",
    }

    this.setPersonalisedBlockStyles()
    this.showLoader()
    this.process6sense(data)
    this.events()
  }

  events() {
    document.addEventListener("DOMContentLoaded", () => {
      this.removeInlineHeightStyles()
      setTimeout(() => this.removeInlineHeightStyles(), 100)
    })
  }

  removeInlineHeightStyles() {
    this.block.style.minHeight = ""
  }

  //function to return entire query string
  getQueryString() {
    // Get the query string part of the URL
    const queryString = window.location.search

    // Remove the leading "?" from the query string, if present
    return queryString.replace(/^\?/, "")
  }

  //function to get the value of a query string parameter by name
  getQueryParamValue(key, queryString) {
    // Remove the leading "?" from the query string, if present
    queryString = queryString.replace(/^\?/, "")

    // Split the query string into individual key-value pairs
    const queryParams = queryString.split("&")

    // Iterate through the key-value pairs
    for (const param of queryParams) {
      // Split each pair into key and value
      const [paramKey, paramValue] = param.split("=")

      // If the current key matches the desired key, return its value
      if (paramKey === key) {
        // Decode the URI component to handle special characters
        return decodeURIComponent(paramValue)
      }
    }

    // If the key is not found, return null or handle it as needed
    return null
  }

  // function to show the loading animation for slow internet users
  showLoader() {
    // Your HTML string
    const loaderHTML = '<div class="loader-wrap"><div class="loader"><div></div><div></div><div></div>Loading...</div></div>'

    // Create a temporary container element
    const tempContainer = document.createElement("div")
    tempContainer.innerHTML = loaderHTML

    // Insert the loader HTML immediately after the opening <body> tag
    document.body.insertBefore(tempContainer.firstChild, document.body.firstChild)
  }

  //function to stop the loading animation
  hideLoader() {
    const loaderWrapElement = document.querySelector(".loader-wrap")

    // Hide the loader-wrap element
    if (loaderWrapElement) {
      loaderWrapElement.style.display = "none"
    }
  }

  //for core web vitals
  setPersonalisedBlockStyles() {
    // Find the child element with data-default-block="true"
    let defaultBlock = this.block.querySelector('[data-default-block="true"]')

    // Check if the child element is found
    if (defaultBlock) {
      // Store the original display and opacity properties
      let originalDisplay = defaultBlock.style.display
      let originalOpacity = defaultBlock.style.opacity

      // Temporarily set display to block and opacity to 0 to get the height
      defaultBlock.style.display = "block"
      defaultBlock.style.opacity = 0

      // Get the calculated height of the child element
      let defaultBlockHeight = defaultBlock.clientHeight

      // Revert the display and opacity properties back to their original values
      defaultBlock.style.display = originalDisplay
      defaultBlock.style.opacity = originalOpacity

      // Apply the height to the current element from the original variable
      this.block.style.minHeight = `${defaultBlockHeight}px`
    }
  }

  //function to compare values of objects
  compareValues(data, criteria) {
    /* console.log(data);
    console.log(criteria); */
    // if is a query string test

    data = this.splitit_test !== null ? this.override6SenseData(this.splitit_test, data) : data

    // overwrite data with value we actually want
    //data = data[criteria[0]][criteria[1]] ?? false; - compiler now failing to compile this due to the ?? operator, previously worked, weird.

    // get keys
    this.keys.parent = criteria.shift()
    this.keys.child = criteria.shift()

    // get subset of data
    data = this.keys.parent && this.keys.child ? (this.keys.child === "subindustry" ? data[this.keys.parent]["industry_v2"][0][this.keys.child] : data[this.keys.parent][this.keys.child]) : false

    // return false if 6Sense data does not contain top level / sub keys
    if (!data) return false

    // specific handlers for array of objects
    // if(['company.subindustry', 'segments.list'].includes(keys.parent + '.' + keys.child)) recursiveSearch(criteria, data, keys.parent + '.' + keys.child);

    // array or string specific
    return Array.isArray(data) ? recursiveSearch(criteria, data, this.keys.parent + "." + this.keys.child) : criteria.find((el) => el == this.conditionalLowerCase(data))
  }

  override6SenseData(overrides, data) {
    // split string into array
    overrides = overrides.toLowerCase().split("|")

    // get keys
    this.keys.parent = overrides.shift()
    this.keys.child = overrides.shift()

    // convert the overrides back into a string
    overrides = overrides.join("")

    // more specific overrides
    switch (this.keys.parent + "." + this.keys.child) {
      case "company.subindustry":
        data["company"]["industry_v2"][0]["subindustry"] = overrides
        return data

      case "segments.list":
        data["segments"]["list"][0]["id"] = overrides
        return data
    }

    // string or array overrides
    if (Array.isArray(data[this.keys.parent][this.keys.child])) {
      data[this.keys.parent][this.keys.child][0] = overrides
    } else {
      data[this.keys.parent][this.keys.child] = overrides
    }
    return data
  }

  recursiveSearch(needles, haystack, type = "") {
    let target_key = ""

    switch (type) {
      case "company.subindustry":
        target_key = "subindustry"
        break

      case "segments.list":
        target_key = "id"
        break
    }

    for (let needle of needles) {
      if (
        haystack.find((item) => {
          item = target_key !== "" ? item[target_key] : item
          return this.conditionalLowerCase(item) == needle
        })
      ) {
        return true
      }
    }

    // no match found
    return false
  }

  conditionalLowerCase(val) {
    return typeof val === "string" ? val.toLowerCase() : val
  }

  //helper function for hero v2 siblings
  processHeroV2Element(variant) {
    // Check if variant has the 'hero-v2' class
    if (variant.classList.contains("hero-v2")) {
      /* console.log('classlist contains hero-v2');
      console.log(variant); */

      // Initialize variables to store the found elements
      var reviewHtmlElement = null
      var breadcrumbElement = null

      // Iterate through the next siblings of the specific element
      var currentElement = variant.nextElementSibling
      while (currentElement && !currentElement.classList.contains("hero-v2")) {
        if (currentElement.classList.contains("hero-v2--expanded__review_html")) {
          reviewHtmlElement = currentElement
        } else if (currentElement.classList.contains("hero-breadcrumb")) {
          breadcrumbElement = currentElement
        }

        // Move to the next sibling
        currentElement = currentElement.nextElementSibling
      }

      // Add the class if the reviewHtmlElement exists
      if (reviewHtmlElement) {
        reviewHtmlElement.classList.add("show-personalised-block")
      }

      // Add the class if the breadcrumbElement exists
      if (breadcrumbElement) {
        breadcrumbElement.classList.add("show-personalised-block")
      }

      // Check if at least one element is found
      if (reviewHtmlElement || breadcrumbElement) {
        console.log("At least one element found:", reviewHtmlElement, breadcrumbElement)
      } else {
        console.log("Both elements not found.")
      }
    }
  }

  //function to process 6Sense data, or query string data
  processResponseData(data) {
    //console.log("Processing data:", data)

    //stop showing the loading animation
    this.hideLoader()

    let matchFound = false // Flag to track if any match is found within the variants loop

    for (let variant of this.block.querySelectorAll("[data-personalisation]")) {
      let criteria = variant.getAttribute("data-personalisation")

      if (criteria !== "false") {
        criteria = criteria.split("|")

        if (this.compareValues(data, criteria)) {
          // console.log("objects match")
          variant.classList.add("show-personalised-block")
          //add check for if hero v2 to show breadcrumbs and reviews, if set
          if (variant.classList.contains("hero-v2")) {
            this.processHeroV2Element(variant)
          }
          matchFound = true
          break // Exit the loop once a match is found
        }
      }
    }

    // Check if a match was found within the variants loop
    if (!matchFound) {
      // Show default block
      console.log("no match found, show default block")
      let defaultBlockElement = this.block.querySelector('[data-default-block="true"]')
      // If an element is found, get the value of its data-personalisation-id attribute
      if (defaultBlockElement) {
        defaultBlockElement.classList.add("show-personalised-block")
        if (defaultBlockElement.classList.contains("hero-v2")) {
          this.processHeroV2Element(defaultBlockElement)
        }
      }
    }

    /* //delete elements that don't have the class personalised block
		let nonMatchingElements = personalised_block.querySelectorAll('section:not(.show-personalised-block)');
		nonMatchingElements.forEach(element => {
			element.parentNode.removeChild(element);
		}); */
  }

  //function to show default blocks if there's an error fetching data from API
  showDefaultBlocksErrorCatch() {
    console.log("6Sense Fetch Error: show default blocks")
    let selector = '[data-default-block="true"]'
    let elements = document.querySelectorAll(selector)

    //stop showing the loading animation
    this.hideLoader()

    elements.forEach(function (element) {
      element.classList.add("show-personalised-block")
    })
  }

  process6sense(data) {
    if (this.object_testing_mode) {
      console.log("object testing mode active")
      this.processResponseData(this.test_object)
    } else {
      if (this.splitit_test !== null) {
        console.log("splitit_test query string is not empty and contains the key splitit_test, ready to test:", this.splitit_test)
      }

      this.processResponseData(data)
    }
  }
}

export default PersonalisedBlock
