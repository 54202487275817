class PersonalisedContent {
  constructor(el, data) {
    this.element = el
    this.data = data
    this.companyNameSpan = el.querySelector(".sixsense-company-name")

    //this.fetchData()
    this.process6s(data)
  }

  toTitleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  }

  process6s(data) {
    let companyName = data.company.name ? this.toTitleCase(data.company.name) : "your company"

    if (this.companyNameSpan) {
      this.companyNameSpan.textContent = this.toTitleCase(companyName)
      //console.log("Content updated successfully.")
      this.element.classList.add("sixsense-personalised-content--fade-in")
    } else {
      console.log("Nested element '.sixsense-company-name' not found.")
    }
  }
}

export default PersonalisedContent
