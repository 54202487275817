import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ParallaxContent {
  constructor(block) {
    this.block = block
    this.background = block.querySelector(".parallax-content__background")
    this.content = block.querySelector(".parallax-content .container")
    this.items = block.querySelectorAll(".parallax-content__list-item")
    this.gsapInit()
  }

  gsapInit() {
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: () => "top top",
        end: () => `+=${this.content.offsetHeight}`,
        scrub: true,
        pin: this.block,
      },
    })

    this.timeline.from(
      this.content,
      {
        y: () => this.content.offsetHeight,
        duration: 1,
      },
      0
    )

    this.timeline.to(
      this.background,
      {
        width: "50%",
        height: "100%",
        duration: 0.5,
      },
      0
    )

    this.timeline.to(this.block, {"--overlay-opacity": 1, duration: 0.25}, 0)
  }
}

export default ParallaxContent
