import "bootstrap"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

//Modules
import Header from "./components/header"
import SecondaryHeader from "./components/secondaryHeader"
import Footer from "./components/footer"
import ContentColumnsIcons from "./components/contentColumnsIcons"
import ScrollableTabs from "./components/scrollableTabs"
import ContentTextOnly from "./components/contentTextOnly"
import ContentImageRepeatTextBlocks from "./components/contentImageRepeatTextBlocks"
import CustomComparisonTable from "./components/customComparisonTable"
import CarouselQuote from "./components/carouselQuote"
import ListingSuccessStories from "./components/listingSuccessStories"
import CustomFaq from "./components/customFaq"
import Team from "./components/team"
import ClientLogos from "./components/clientLogos"
import LiveChat from "./components/liveChat"
import ContentHero from "./components/contentHero"
import CollageHero from "./components/collageHero"
import ContentVideo from "./components/contentVideo"
import ImageTextParallax from "./components/imageTextParallax"
import ContentColumnsStatistics from "./components/contentColumnsStatistics"
import CustomPricing from "./components/customPricing"
import ParallaxPhone from "./components/parallaxPhone"
import StaticQuote from "./components/staticQuote"
import ImageText from "./components/imageText"
import ContentOnPageImage from "./components/contentOnPageImage"
import SixSense from "./components/6sense"

//Banking page
import AnimatedStats from "./components/banking-page/animatedStats"
import AnimatedGlobe from "./components/banking-page/globe"
import HoverCarousel from "./components/banking-page/hoverCarousel"
import ParallaxContent from "./components/banking-page/parallaxContent"
import ParallaxContent2 from "./components/banking-page/parallaxContent2"
import ThreeHero from "./components/banking-page/threeHero"

for (const block of document.querySelectorAll(".header")) new Header(block)
for (const block of document.querySelectorAll(".secondary-header")) new SecondaryHeader(block)
for (const block of document.querySelectorAll(".footer")) new Footer(block)
for (const block of document.querySelectorAll(".content-columns-icons")) new ContentColumnsIcons(block)
for (const block of document.querySelectorAll(".scrollable-tabs")) new ScrollableTabs(block)
for (const block of document.querySelectorAll(".content-text-only")) new ContentTextOnly(block)
for (const block of document.querySelectorAll(".content-image-repeat-text-blocks")) new ContentImageRepeatTextBlocks(block)
for (const block of document.querySelectorAll(".custom-comparison-table")) new CustomComparisonTable(block)
for (const block of document.querySelectorAll(".carousel-quote")) new CarouselQuote(block)
for (const block of document.querySelectorAll(".listing-success-stories")) new ListingSuccessStories(block)
for (const block of document.querySelectorAll(".custom-faq")) new CustomFaq(block)
for (const block of document.querySelectorAll(".team")) new Team(block)
for (const block of document.querySelectorAll(".client-logos")) new ClientLogos(block)
for (const block of document.querySelectorAll(".live-chat")) new LiveChat(block)
for (const block of document.querySelectorAll(".content-hero")) new ContentHero(block)
for (const block of document.querySelectorAll(".collage-hero")) new CollageHero(block)
for (const block of document.querySelectorAll(".content-columns-statistics")) new ContentColumnsStatistics(block)
for (const block of document.querySelectorAll(".content-video")) new ContentVideo(block)
for (const block of document.querySelectorAll(".image-text-parallax")) new ImageTextParallax(block)
for (const block of document.querySelectorAll(".custom-pricing")) new CustomPricing(block)
for (const block of document.querySelectorAll(".parallax-phone")) new ParallaxPhone(block)
for (const block of document.querySelectorAll(".static-quote")) new StaticQuote(block)
for (const block of document.querySelectorAll(".image-text")) new ImageText(block)
for (const block of document.querySelectorAll(".content-on-page-image")) new ContentOnPageImage(block)

new SixSense()

//Banking page
for (const block of document.querySelectorAll(".three-hero")) new ThreeHero(block)
for (const block of document.querySelectorAll(".animated-stats")) new AnimatedStats(block)
for (const block of document.querySelectorAll(".hover-carousel")) new HoverCarousel(block)
for (const block of document.querySelectorAll(".globe")) new AnimatedGlobe(block)
for (const block of document.querySelectorAll(".parallax-content")) new ParallaxContent(block)
for (const block of document.querySelectorAll(".parallax-content-2")) new ParallaxContent2(block)
