import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ContentColumnsIcons {
  constructor(block) {
    this.block = block
    this.cardsWrapper = block.querySelector(".content-columns-icons__wrapper")
    this.cards = block.querySelectorAll(".content-columns-icons__card")
    this.card1 = block.querySelector(".content-columns-icons__card--1")
    this.card2 = block.querySelector(".content-columns-icons__card--2")
    this.headingSpan = block.querySelector(".content-columns-icons__heading span")
    this.content = block.querySelectorAll(".content-columns-icons__inner > *")

    this.gsapInit()
    this.events()
  }

  events() {
    if (this.block.classList.contains("content-columns-icons--expanding")) {
      this.cards.forEach((card) => {
        card.addEventListener("mouseenter", () => this.activateCard(card))
      })
    }
  }

  activateCard(card) {
    this.cards.forEach((el) => {
      el.classList.toggle("content-columns-icons__card--active", el == card)
    })
  }

  gsapInit() {
    const mm = gsap.matchMedia()

    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        invalidateOnRefresh: true,
        start: () => "top 75%",
        toggleActions: "play none none reverse",
      },
      ease: "power2.inOut",
    })

    if (this.block.dataset.animations) {
      if (this.headingSpan) {
        this.timeline.fromTo(
          this.headingSpan,
          {
            "--width": "0%",
          },
          {
            "--width": "104%",
            duration: 0.5,
            delay: 0.5,
          },
          0
        )
      }

      if (this.content) {
        this.timeline.fromTo(
          this.content,
          {
            opacity: 0,
            y: 50,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          },
          0
        )
      }
    }

    if (this.block.classList.contains("content-columns-icons--parallax")) {
      mm.add("(min-width: 992px)", () => {
        this.cards.forEach((card, index) => {
          this.timeline.fromTo(
            card,
            {
              x: () => `${(this.cards.length - index - 1) * card.offsetWidth + (this.cards.length - index - 1) * 20}px`,
            },
            {
              x: () => 0,
              duration: 0.5,
              // delay: 0.5,
              ease: "power2.in",
            },
            0
          )
        })
      })
    }
  }
}

export default ContentColumnsIcons
