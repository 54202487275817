import PersonalisedBlock from "./personalisedBlock"
import PersonalisedContent from "./personalisedContent"
import SenseShortcode from "./6sense-shortcode"
import GatedStandaloneForm from "./gatedStandaloneForm"
import CustomForm from "./customForm"
import Report from "./report"

class SixSense {
  constructor() {
    this.apiUrl = "https://epsilon.6sense.com/v3/company/details"
    this.authToken = "3ddf68cd17ce060f533740f156717f3f09fa72ab"
    this.blocks = document.querySelectorAll(".personalised-block")
    this.contents = document.querySelectorAll(".sixsense-personalised-content")
    this.shortcodes = document.querySelectorAll(".sense-shortcode")
    this.gatedForms = document.querySelectorAll(".gated-standalone-form")
    this.customForms = document.querySelectorAll(".custom-form")
    this.reports = document.querySelectorAll(".report")

    if (this.blocks.length || this.contents.length || this.shortcodes.length || this.gatedForms.length || this.customForms.length || this.reports.length) {
      this.getData()
    } else {
      console.log("No 6sense blocks found.")
    }
  }

  getData() {
    fetch(this.apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Token ${this.authToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("Response status:", response.status)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        return response.json()
      })
      .then((data) => {
        console.log("Response data:", data)
        for (const el of this.blocks) new PersonalisedBlock(el, data)
        for (const el of this.contents) new PersonalisedContent(el, data)
        for (const el of this.shortcodes) new SenseShortcode(el, data)
        for (const el of this.gatedForms) new GatedStandaloneForm(el, data)
        for (const el of this.customForms) new CustomForm(el, data)
        for (const el of this.reports) new Report(el, data)
      })
      .catch((error) => {
        console.error("Fetch error:", error.message)

        const sense = {
          company: {
            domain: "Failed to fetch",
            name: "",
            region: "",
            country: "",
            state: "",
            city: "",
            industry: "",
            companyId: "",
            country_iso_code: "",
            address: "",
            zip: "",
            phone: "",
            employee_range: "",
            revenue_range: "",
            employee_count: "",
            annual_revenue: "",
            is_blacklisted: false,
            state_code: "",
            is_6qa: false,
            geoIP_country: "",
            geoIP_state: "",
            geoIP_city: "",
            company_match: "Non-actionable Match",
            additional_comment: "There is no valid B2B use case as this was matched to something other than a company name/domain (i.e. bot, host, noisy signal, etc.)",
            sic: "",
            sic_description: "",
            naics: "",
            naics_description: "",
            industry_v2: [],
          },
          scores: [],
          segments: {
            ids: [],
            names: [],
            list: [],
          },
          confidence: "NA",
        }

        for (const el of this.blocks) new PersonalisedBlock(el, sense)
        for (const el of this.contents) new PersonalisedContent(el, sense)
        for (const el of this.shortcodes) new SenseShortcode(el, sense)
        for (const el of this.gatedForms) new GatedStandaloneForm(el, sense)
        for (const el of this.customForms) new CustomForm(el, sense)
        for (const el of this.reports) new Report(el, sense)
      })
  }
}

export default SixSense
