export default class GatedStandaloneForm {
  constructor(el, data) {
    this.el = el
    this.data = data
    this.recaptchaField = el.querySelector("#recaptcha_token")
    this.events()
  }

  events() {
    document.addEventListener("DOMContentLoaded", () => {
      if (document.querySelector(".gated-standalone-form")) {
        this.processGated6SenseHiddenFields()
      }

      if (document.getElementById("gatedform")) {
        this.scrollToGatedFormIfError()
      }
    })
    this.el.addEventListener("submit", (e) => {
      e.preventDefault()
      this.populateRecaptchaField()

      setTimeout(() => {
        this.el.submit()
      }, 2000)
    })
  }

  processGated6SenseHiddenFields() {
    console.log("Processing data from 6sense gated-standalone-form:", this.data)
    const sixsense_company = document.querySelector('.gated-standalone-form input[name="6s-company"]')
    if (sixsense_company) {
      sixsense_company.value = this.data.company.name
    }
    const sixsense_company_annual_revenue = document.querySelector('.gated-standalone-form input[name="6s-annual-revenue"]')
    if (sixsense_company_annual_revenue) {
      sixsense_company_annual_revenue.value = this.data.company.annual_revenue
    }
    const sixsense_company_country = document.querySelector('.gated-standalone-form input[name="6s-country"]')
    if (sixsense_company_country) {
      sixsense_company_country.value = this.data.company.country
    }
    const sixsense_company_domain = document.querySelector('.gated-standalone-form input[name="6s-website"]')
    if (sixsense_company_domain) {
      sixsense_company_domain.value = this.data.company.domain
    }
    const sixsense_segments_names = document.querySelector('.gated-standalone-form input[name="6s-segments"]')
    if (sixsense_segments_names) {
      sixsense_segments_names.value = this.data.segments.names
    }
  }

  getQueryParams() {
    const params = {}
    const queryString = window.location.search.substring(1)
    const regex = /([^&=]+)=([^&]*)/g
    let match
    while ((match = regex.exec(queryString))) {
      params[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
    }
    return params
  }

  scrollToGatedFormIfError() {
    const queryParams = this.getQueryParams()

    if (queryParams.errorMessage) {
      const element = document.getElementById("gatedform")
      if (element) {
        const header = document.querySelector(".site-header")
        const headerHeight = header ? header.offsetHeight : 0

        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
        const offsetPosition = elementPosition - headerHeight

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        })
      }
    }
  }

  async populateRecaptchaField() {
    //Check grecaptcha object exists
    if (!grecaptcha) {
      console.log("Grecaptcha does not exist.")
      return
    }

    grecaptcha.ready(async () => {
      const token = await grecaptcha.execute(recaptchasettings.site_key, {
        action: "submit",
      })
      this.recaptchaField.value = token
    })
  }
}
