import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class AnimatedStats {
  constructor(block) {
    this.block = block
    this.stats = block.querySelectorAll(".animated-stats__stat strong span")
    this.arrows = block.querySelectorAll(".animated-stats__stat svg")

    this.gsapInit()
  }

  gsapInit() {
    if (this.arrows.length) {
      gsap.from(this.arrows, {
        scrollTrigger: {
          trigger: this.block,
          start: "top 50%",
        },
        duration: 1,
        ease: "sine.inOut",
        opacity: 0,
        y: "100%",
        stagger: 0.5,
      })
    }
  }
}

export default AnimatedStats
