import * as THREE from "three"
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader.js"
import debounce from "lodash/debounce"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

class ParallaxContent2 {
  constructor(block) {
    this.block = block
    this.inner = block.querySelector(".parallax-content-2__inner")
    this.items = block.querySelectorAll(".parallax-content-2__item")
    this.threeCanvas = block.querySelector(".parallax-content-2__three-canvas")
    this.canvases = block.querySelectorAll(".parallax-content-2__canvas")

    this.previousIndex = 0
    this.currentIndex = 0
    this.animationActive = true

    this.setCanvasHeights()
    this.setItemsCount()
    this.gsapInit()
    this.threeInit()
    this.phones()
    this.customers2()
    this.merchants2()
    this.issuers2()
    this.animate()
    this.events()
  }

  events() {
    window.addEventListener("resize", () => {
      this.setCanvasHeights()
    })

    this.threeCanvas?.addEventListener("webglcontextlost", () => {
      this.animationActive = false
      this.renderer.dispose()
      this.threeCanvas.classList.add("parallax-content-2__three-canvas--hidden")
      console.log("Context lost, canvas hidden.")
    })
  }

  setCanvasHeights() {
    this.canvases.forEach((canvas) => {
      canvas.style.setProperty("--height", `${canvas.offsetHeight}px`)
    })
  }

  setItemsCount() {
    this.block.style.setProperty("--count", this.items.length)
  }

  gsapInit() {
    this.merchantsRValue = this.block.querySelector("#merchants-r-number")
    this.merchantsGValue = this.block.querySelector("#merchants-g-number")
    this.merchantsBValue = this.block.querySelector("#merchants-b-number")

    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.block,
        start: "top center",
        end: "bottom center",
        scrub: true,
        // markers: true,
        onUpdate: (self) => {
          this.currentIndex = Math.min(Math.max(Math.ceil(self.progress * this.canvases.length - 1), 0), 3)

          if (this.currentIndex != this.previousIndex) {
            this.canvases.forEach((canvas, index) => {
              canvas.classList.toggle("parallax-content-2__canvas--active", index == this.currentIndex)
            })

            if (this.currentIndex == 0 && this.phonesTimeline && window.innerWidth >= 992) {
              this.phonesTimeline.restart()
            }

            if (this.currentIndex == 1 && this.customersTimeline && window.innerWidth >= 992) {
              this.customersTimeline.restart()
            }

            if (this.currentIndex == 2 && this.merchantsTimeline && window.innerWidth >= 992) {
              this.merchantsTimeline.restart()
            }

            if (this.currentIndex == 3 && this.issuersTimeline && window.innerWidth >= 992) {
              this.issuersTimeline.restart()
            }

            this.previousIndex = this.currentIndex
          }
        },
      },
    })

    this.phonesTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: () => (window.innerWidth >= 992 ? this.block : this.block.querySelector(".parallax-content-2__canvas--1")),
        start: "top bottom",
        end: "bottom top",
        toggleActions: "restart none restart none",
      },
    })

    this.customersTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: () => (window.innerWidth >= 992 ? this.block : this.block.querySelector(".parallax-content-2__canvas--2")),
        start: "top bottom",
        end: "bottom top",
        toggleActions: "restart none restart none",
      },
      repeat: -1,
      repeatDelay: 0,
    })

    this.merchantsTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: () => (window.innerWidth >= 992 ? this.block : this.block.querySelector(".parallax-content-2__canvas--3")),
        start: "top bottom",
        end: "bottom top",
        toggleActions: "restart none restart none",
      },
      repeat: -1,
      repeatDelay: 0,
      onStart: () => {
        this.merchantsRValue.textContent = 0
        this.merchantsGValue.textContent = 0
        this.merchantsBValue.textContent = 0
      },
      onRepeat: () => {
        this.merchantsRValue.textContent = 0
        this.merchantsGValue.textContent = 0
        this.merchantsBValue.textContent = 0
      },
    })

    this.issuersTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: () => (window.innerWidth >= 992 ? this.block : this.block.querySelector(".parallax-content-2__canvas--4")),
        start: "top bottom",
        end: "bottom top",
        toggleActions: "restart none restart none",
      },
      repeat: -1,
      repeatDelay: 0,
    })
  }

  threeInit() {
    const target = this.block.querySelector(".parallax-content-2__canvas--1")

    //Clock
    this.clock = new THREE.Clock()

    //Loader
    this.GLTFLoader = new GLTFLoader()

    //Scene
    this.scene = new THREE.Scene()
    this.scene.background = null

    //Camera
    this.camera = new THREE.PerspectiveCamera(30, target.offsetWidth / target.offsetHeight, 0.1, 1000)
    this.camera.position.set(0, 0, 1)
    this.scene.add(this.camera)

    //Renderer
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      canvas: this.threeCanvas,
      powerPreference: "low-power",
    })
    this.renderer.setSize(target.offsetWidth, target.offsetHeight)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    // target.appendChild(this.renderer.domElement)

    //Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.1)
    this.scene.add(ambientLight)

    const pointLight = new THREE.PointLight(0xffffff, 5)
    pointLight.position.set(-0.25, 0.5, 2)
    this.scene.add(pointLight)

    //Resize
    window.addEventListener(
      "resize",
      debounce(() => {
        this.camera.aspect = target.offsetWidth / target.offsetHeight
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(target.offsetWidth, target.offsetHeight)
      }, 250)
    )
  }

  async phones() {
    const [phone1, phone2, phone3] = await Promise.all([this.GLTFLoader.loadAsync("/wp-content/themes/designbypelling/js/src/models/phone-checkout.glb"), this.GLTFLoader.loadAsync("/wp-content/themes/designbypelling/js/src/models/phone-confirmed.glb"), this.GLTFLoader.loadAsync("/wp-content/themes/designbypelling/js/src/models/phone-statement.glb")])

    this.phoneGroup = new THREE.Group()
    this.phoneGroup1 = new THREE.Group()
    this.phoneGroup2 = new THREE.Group()
    this.phoneGroup3 = new THREE.Group()

    //Phone 1
    this.phoneScene1 = phone1.scene
    this.phoneGroup1.add(this.phoneScene1)
    this.phoneScene1.scale.set(0.7, 0.7, 0.7)
    this.phoneScene1.position.set(0, -0.04, 0.125)
    this.phoneScene1.rotation.y = THREE.MathUtils.degToRad(180)
    this.phoneGroup1.position.set(0, 0, 0.0)

    //Phone 2
    this.phoneScene2 = phone2.scene
    this.phoneGroup2.add(this.phoneScene2)
    this.phoneScene2.scale.set(0.7, 0.7, 0.7)
    this.phoneScene2.position.set(0, -0.04, 0.125)
    this.phoneScene2.rotation.y = THREE.MathUtils.degToRad(60)
    this.phoneGroup2.position.set(0, 0, 0.0)
    this.phoneGroup2.rotation.y = THREE.MathUtils.degToRad(120)

    //Phone 3
    this.phoneScene3 = phone3.scene
    this.phoneGroup3.add(this.phoneScene3)
    this.phoneScene3.scale.set(0.7, 0.7, 0.7)
    this.phoneScene3.position.set(0, -0.04, 0.125)
    this.phoneScene3.rotation.y = THREE.MathUtils.degToRad(-60)
    this.phoneGroup3.position.set(0, 0, 0.0)
    this.phoneGroup3.rotation.y = THREE.MathUtils.degToRad(240)

    this.phoneGroup.add(this.phoneGroup1)
    this.phoneGroup.add(this.phoneGroup2)
    this.phoneGroup.add(this.phoneGroup3)
    this.phoneGroup.position.set(0, 0, 0)
    this.scene.add(this.phoneGroup)

    this.phonesTimeline.from(this.canvases[0].querySelector("canvas"), {
      opacity: 0,
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.phonesTimeline.from(this.phoneGroup2.position, {z: -0.1, x: -0.09, duration: 1, ease: "sine.out"}, 1)

    this.phonesTimeline.from(this.phoneGroup3.position, {z: -0.1, x: 0.09, duration: 1, ease: "sine.out"}, 1)

    this.phonesTimeline.to(
      this.phoneGroup.rotation,
      {
        y: `+=${THREE.MathUtils.degToRad(360)}`,
        duration: 10,
        ease: "none",
        repeat: -1,
      },
      3
    )

    this.phonesTimeline.to(
      [this.phoneScene1.rotation, this.phoneScene2.rotation, this.phoneScene3.rotation],
      {
        y: `-=${THREE.MathUtils.degToRad(360)}`,
        duration: 10,
        ease: "none",
        repeat: -1,
      },
      3
    )
  }

  customers() {
    this.customersSvg = this.block.querySelector(".parallax-content-2__canvas--2 svg")
    this.customersGroup1 = this.block.querySelector(".customers-group-1")
    this.customersGroup2 = this.block.querySelector(".customers-group-2")
    this.customersGroup1Inner = this.block.querySelector(".customers-group-1__inner")
    this.customersGroup2Inner = this.block.querySelector(".customers-group-2__inner")
    this.customersPhone = this.block.querySelector(".customers-phone")
    this.customersBase = this.block.querySelector(".customers-base")
    this.customersBasePhone = this.block.querySelector(".customers-fade-group")
    this.customersCard = this.block.querySelector(".customers-card")
    this.customersCredit = this.block.querySelector(".customers-credit")
    this.customersReward = this.block.querySelector(".customers-reward")
    this.customersReward2 = this.block.querySelector(".customers-reward-2")

    gsap.set(this.customersSvg, {opacity: 0})
    gsap.set([this.customersCard, this.customersCredit, this.customersReward2], {
      transformOrigin: "50% 50%",
    })
    gsap.set(this.customersCredit, {
      opacity: 0,
      scale: 1.75,
    })
    gsap.set(this.customersGroup2Inner, {
      x: "100%",
    })
    gsap.set(this.customersReward2, {
      opacity: 0,
    })

    this.customersTimeline.to(
      this.customersSvg,
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      0
    )

    this.customersTimeline.to(
      this.customersCard,
      {
        scale: 1.5,
        duration: 0.5,
        ease: "sine.inOut",
      },
      0.5
    )

    this.customersTimeline.to(
      this.customersCredit,
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      0.5
    )

    this.customersTimeline.to(
      [this.customersGroup1, this.customersBasePhone],
      {
        opacity: 0.125,
        duration: 0.5,
        ease: "sine.inOut",
      },
      0.5
    )

    this.customersTimeline.to(
      [this.customersGroup1, this.customersBasePhone],
      {
        opacity: 1,
        duration: 1,
        ease: "sine.inOut",
      },
      2
    )

    this.customersTimeline.to(
      this.customersCard,
      {
        scale: 1,
        duration: 1,
        ease: "sine.inOut",
      },
      2
    )

    this.customersTimeline.to(
      this.customersCredit,
      {
        opacity: 0,
        duration: 1,
        ease: "sine.inOut",
      },
      2
    )

    this.customersTimeline.to(
      this.customersGroup2Inner,
      {
        x: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      3
    )

    this.customersTimeline.to(
      this.customersGroup1,
      {
        opacity: 0,
        duration: 0,
      },
      3.5
    )

    this.customersTimeline.to(
      this.customersReward2,
      {
        opacity: 1,
        duration: 0,
      },
      3.5
    )

    this.customersTimeline.to(
      this.customersReward2,
      {
        scale: 1.5,
        duration: 0.5,
        ease: "sine.inOut",
      },
      4
    )

    this.customersTimeline.to(
      [this.customersGroup1, this.customersBasePhone, this.customersCard, this.customersGroup2],
      {
        opacity: 0.125,
        duration: 0.5,
        ease: "sine.inOut",
      },
      4
    )

    this.customersTimeline.to(
      this.customersSvg,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=2"
    )
  }

  merchants() {
    this.merchantsSvg = this.block.querySelector(".parallax-content-2__canvas--3 svg")
    this.merchantsGroup1 = this.block.querySelector(".merchants-group-1")
    this.merchantsGroup2 = this.block.querySelector(".merchants-group-2")
    this.merchantsMessageText = this.block.querySelectorAll(".merchants-message-text path")
    this.merchantsBambooText = this.block.querySelectorAll(".merchants-bamboo-text path")
    this.merchantsColorPicker = this.block.querySelector(".merchants-color-picker")
    this.merchantsColorSlider = this.block.querySelector(".merchants-color-slider circle")
    this.merchantsColorPreview = this.block.querySelector(".merchants-color-preview")

    gsap.set(this.merchantsSvg, {opacity: 0})
    gsap.set(this.merchantsGroup2, {opacity: 0, transformOrigin: "50% 50%", scale: 1.25})
    gsap.set(this.merchantsMessageText, {opacity: 0})
    gsap.set(this.merchantsBambooText, {opacity: 0})
    gsap.set(this.merchantsColorPicker, {opacity: 0})

    this.merchantsTimeline.to(
      this.merchantsSvg,
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      0
    )

    this.merchantsTimeline.to(
      this.merchantsGroup1,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      1.5
    )

    this.merchantsTimeline.to(
      this.merchantsGroup2,
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      1.5
    )

    this.merchantsTimeline.to(
      this.merchantsMessageText,
      {
        opacity: 1,
        duration: 0,
        stagger: 0.05,
        ease: "sine.inOut",
      },
      2.5
    )

    this.merchantsTimeline.to(
      this.merchantsBambooText,
      {
        opacity: 1,
        duration: 0,
        stagger: 0.05,
        ease: "sine.inOut",
      },
      2.5
    )

    this.merchantsTimeline.to(
      this.merchantsColorPicker,
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.merchantsTimeline.to(
      this.merchantsColorSlider,
      {
        attr: {
          cx: 863.6,
          cy: 1445.3,
          fill: "#B249C8",
        },
        duration: 2,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.merchantsTimeline.to(
      [this.merchantsBambooText, this.merchantsColorPreview],
      {
        attr: {
          fill: "#B249C8",
        },
        duration: 2,
        ease: "sine.inOut",
      },
      "-=2"
    )

    this.merchantsTimeline.to(
      this.merchantsRValue,
      {
        duration: 2,
        ease: "sine.inOut",
        onUpdate: function () {
          this._targets[0].textContent = Math.round(178 * this.progress())
        },
      },
      "-=2"
    )

    this.merchantsTimeline.to(
      this.merchantsGValue,
      {
        duration: 2,
        ease: "sine.inOut",
        onUpdate: function () {
          this._targets[0].textContent = Math.round(73 * this.progress())
        },
      },
      "-=2"
    )

    this.merchantsTimeline.to(
      this.merchantsBValue,
      {
        duration: 2,
        ease: "sine.inOut",
        onUpdate: function () {
          this._targets[0].textContent = Math.round(200 * this.progress())
        },
      },
      "-=2"
    )

    this.merchantsTimeline.to(
      this.merchantsSvg,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=2"
    )
  }

  issuers() {
    this.issuersSvg = this.block.querySelector(".parallax-content-2__canvas--4 svg")
    this.issuersGroup1 = this.block.querySelector(".issuers-group-1")
    this.issuersGroup2 = this.block.querySelector(".issuers-group-2")
    this.issuersCardSelection = this.block.querySelector(".issuers-card-selection")
    this.issuersCardNumber = this.block.querySelectorAll(".issuers-card-number path")
    this.issuersCardNumberPlaceholder = this.block.querySelector(".issuers-card-number-placeholder")
    this.issuersCardDate = this.block.querySelectorAll(".issuers-card-date path")
    this.issuersCardDatePlaceholder = this.block.querySelector(".issuers-card-date-placeholder")
    this.issuersCardVerification = this.block.querySelectorAll(".issuers-card-verification path")
    this.issuersCardVerificationPlaceholder = this.block.querySelector(".issuers-card-verification-placeholder")
    this.issuersPlanSelection = this.block.querySelector(".issuers-plan-selection")
    this.issuersTick = this.block.querySelector(".issuers-tick")
    this.issuersTickbox = this.block.querySelector(".issuers-tickbox")
    this.issuersJanuary = this.block.querySelector(".issuers-january")
    this.issuersFebruary = this.block.querySelector(".issuers-february")
    this.issuersMarch = this.block.querySelector(".issuers-march")
    this.issuersApril = this.block.querySelector(".issuers-april")
    this.issuersMay = this.block.querySelector(".issuers-may")
    this.issuersJune = this.block.querySelector(".issuers-june")
    this.issuersHeader = this.block.querySelector(".issuers-header")
    this.issuersLast = this.block.querySelector(".issuers-last-transaction")
    this.issuersPending = this.block.querySelector(".issuers-pending-transactions")
    this.issuersBg = this.block.querySelector(".issuers-bg")
    this.issuersInner = this.block.querySelector(".issuers-inner")

    gsap.set(this.issuersSvg, {opacity: 0})
    gsap.set(this.issuersGroup2, {opacity: 0})
    gsap.set([this.issuersJanuary, this.issuersFebruary, this.issuersMarch, this.issuersApril, this.issuersMay, this.issuersJune], {transformOrigin: "50% 50%"})
    gsap.set(this.issuersGroup2, {
      transformOrigin: "50% 50%",
      scale: 1.2,
    })

    this.issuersTimeline.to(
      this.issuersSvg,
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      0
    )

    this.issuersTimeline.to(
      this.issuersCardSelection,
      {
        opacity: 1,
        duration: 0.125,
        ease: "sine.inOut",
      },
      0.5
    )

    this.issuersTimeline.to(
      this.issuersCardNumberPlaceholder,
      {
        opacity: 0,
        duration: 0,
        ease: "sine.inOut",
      },
      1.5
    )

    this.issuersTimeline.to(
      this.issuersCardNumber,
      {
        opacity: 1,
        duration: 0,
        stagger: 0.05,
        ease: "sine.inOut",
      },
      1.5
    )

    this.issuersTimeline.to(
      this.issuersCardDatePlaceholder,
      {
        opacity: 0,
        duration: 0,
        ease: "sine.inOut",
      },
      "+=0.25"
    )

    this.issuersTimeline.to(this.issuersCardDate, {
      opacity: 1,
      duration: 0,
      stagger: 0.05,
      ease: "sine.inOut",
    })

    this.issuersTimeline.to(
      this.issuersCardVerificationPlaceholder,
      {
        opacity: 0,
        duration: 0,
        ease: "sine.inOut",
      },
      "+=0.25"
    )

    this.issuersTimeline.to(this.issuersCardVerification, {
      opacity: 1,
      duration: 0,
      stagger: 0.05,
      ease: "sine.inOut",
    })

    this.issuersTimeline.to(
      this.issuersPlanSelection,
      {
        opacity: 1,
        duration: 0.125,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.issuersTimeline.to(
      this.issuersTick,
      {
        opacity: 1,
        duration: 0.125,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.issuersTimeline.to(
      this.issuersTickbox,
      {
        attr: {
          fill: "#000000",
        },
        duration: 0.125,
        ease: "sine.inOut",
      },
      "-=0.125"
    )

    this.issuersTimeline.to(
      this.issuersGroup1,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.issuersTimeline.to(
      this.issuersGroup2,
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    //Jan
    this.issuersTimeline.to(
      this.issuersJanuary,
      {
        scale: 1.19,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=0.5"
    )

    this.issuersTimeline.to(
      [this.issuersFebruary, this.issuersMarch, this.issuersApril, this.issuersMay, this.issuersJune, this.issuersHeader, this.issuersLast, this.issuersPending, this.issuersBg],
      {
        opacity: 0.125,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.issuersTimeline.to(
      this.issuersJanuary,
      {
        scale: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.issuersTimeline.to(
      [this.issuersFebruary, this.issuersMarch, this.issuersApril, this.issuersMay, this.issuersJune, this.issuersHeader, this.issuersLast, this.issuersPending, this.issuersBg],
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.issuersTimeline.to(
      this.issuersInner,
      {
        y: -385,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=0.5"
    )

    //Feb
    this.issuersTimeline.to(
      this.issuersFebruary,
      {
        scale: 1.19,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=0.5"
    )

    this.issuersTimeline.to(
      [this.issuersJanuary, this.issuersMarch, this.issuersApril, this.issuersMay, this.issuersJune, this.issuersHeader, this.issuersLast, this.issuersPending, this.issuersBg],
      {
        opacity: 0.125,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.issuersTimeline.to(
      this.issuersFebruary,
      {
        scale: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.issuersTimeline.to(
      [this.issuersJanuary, this.issuersMarch, this.issuersApril, this.issuersMay, this.issuersJune, this.issuersHeader, this.issuersLast, this.issuersPending, this.issuersBg],
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.issuersTimeline.to(
      this.issuersSvg,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )
  }

  customers2() {
    this.customersSvg = this.block.querySelector(".parallax-content-2__canvas--2 svg")
    this.customersHeader = this.block.querySelector("#customers-header")
    this.customersCard = this.block.querySelector("#customers-card")
    this.customersCredit = this.block.querySelector("#customers-credit")
    this.customersGroup1 = this.block.querySelector("#customers-group-1")
    this.customersGroup2 = this.block.querySelector("#customers-group-2")
    this.customersGroup2Inner = this.block.querySelector("#customers-group-2-inner")
    this.customersReward1 = this.block.querySelector("#customers-reward-1")
    this.customersReward2 = this.block.querySelector("#customers-reward-2")

    gsap.set(this.customersSvg, {
      opacity: 0,
    })
    gsap.set([this.customersCard, this.customersCredit, this.customersReward2], {
      transformOrigin: "50% 50%",
    })
    gsap.set(this.customersCredit, {
      opacity: 0,
      scale: 1.75,
      y: "+=100%",
    })
    gsap.set(this.customersGroup2Inner, {
      x: "100%",
    })
    gsap.set(this.customersReward2, {
      opacity: 0,
    })

    this.customersTimeline.to(this.customersSvg, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.customersTimeline.to(this.customersCard, {
      scale: 1.5,
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.customersTimeline.to(
      this.customersCredit,
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.customersTimeline.to(
      [this.customersHeader, this.customersGroup1],
      {
        opacity: 0.125,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.customersTimeline.to(
      this.customersCard,
      {
        scale: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.customersTimeline.to(
      this.customersCredit,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.customersTimeline.to(
      [this.customersHeader, this.customersGroup1],
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.customersTimeline.to(this.customersGroup2Inner, {
      x: 0,
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.customersTimeline.to(this.customersGroup1, {
      opacity: 0,
      duration: 0,
      ease: "sine.inOut",
    })

    this.customersTimeline.to(this.customersReward1, {
      opacity: 0,
      duration: 0,
      ease: "sine.inOut",
    })

    this.customersTimeline.to(this.customersReward2, {
      opacity: 1,
      duration: 0,
      ease: "sine.inOut",
    })

    this.customersTimeline.to(this.customersReward2, {
      scale: 1.75,
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.customersTimeline.to(
      [this.customersHeader, this.customersHeader, this.customersCard, this.customersGroup2],
      {
        opacity: 0.125,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.customersTimeline.to(
      this.customersSvg,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )
  }

  merchants2() {
    this.merchantsSvg = this.block.querySelector(".parallax-content-2__canvas--3 svg")
    this.merchantsGroup1 = this.block.querySelector("#merchants-group-1")
    this.merchantsGroup2 = this.block.querySelector("#merchants-group-2")
    this.merchantsTextInput = this.block.querySelectorAll("#merchants-text-input path")
    this.merchantsTextOutput = this.block.querySelectorAll("#merchants-text-output path")
    this.merchantsColorPickerGroup = this.block.querySelector("#merchants-color-picker-group")
    this.merchantsColorPicker = this.block.querySelector("#merchants-color-picker circle")
    this.merchantsColorPreview = this.block.querySelector("#merchants-color-display")

    gsap.set(this.merchantsSvg, {
      opacity: 0,
    })
    gsap.set(this.merchantsGroup2, {
      opacity: 0,
    })
    gsap.set([this.merchantsTextInput, this.merchantsTextOutput], {
      opacity: 0,
    })
    gsap.set(this.merchantsColorPickerGroup, {
      opacity: 0,
    })
    gsap.set(this.merchantsTextOutput, {
      attr: {
        fill: "#000000",
      },
    })

    this.merchantsTimeline.to(this.merchantsSvg, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.merchantsTimeline.to(
      this.merchantsGroup1,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.merchantsTimeline.to(
      this.merchantsGroup2,
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.merchantsTimeline.to(this.merchantsTextInput, {
      opacity: 1,
      duration: 0,
      stagger: 0.05,
      ease: "sine.inOut",
    })

    this.merchantsTimeline.to(
      this.merchantsTextOutput,
      {
        opacity: 1,
        duration: 0,
        stagger: 0.05,
        ease: "sine.inOut",
      },
      1.5
    )

    this.merchantsTimeline.to(this.merchantsColorPickerGroup, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.merchantsTimeline.to(
      this.merchantsColorPicker,
      {
        attr: {
          cx: 858,
          cy: 1626,
          fill: "#B249C8",
        },
        duration: 2,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.merchantsTimeline.to(
      [this.merchantsTextOutput, this.merchantsColorPreview],
      {
        attr: {
          fill: "#B249C8",
        },
        duration: 2,
        ease: "sine.inOut",
      },
      "-=2"
    )

    this.merchantsTimeline.to(
      this.merchantsRValue,
      {
        duration: 2,
        ease: "sine.inOut",
        onUpdate: function () {
          this._targets[0].textContent = Math.round(178 * this.progress())
        },
      },
      "-=2"
    )

    this.merchantsTimeline.to(
      this.merchantsGValue,
      {
        duration: 2,
        ease: "sine.inOut",
        onUpdate: function () {
          this._targets[0].textContent = Math.round(73 * this.progress())
        },
      },
      "-=2"
    )

    this.merchantsTimeline.to(
      this.merchantsBValue,
      {
        duration: 2,
        ease: "sine.inOut",
        onUpdate: function () {
          this._targets[0].textContent = Math.round(200 * this.progress())
        },
      },
      "-=2"
    )

    this.merchantsTimeline.to(
      this.merchantsSvg,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )
  }

  issuers2() {
    this.issuersSvg = this.block.querySelector(".parallax-content-2__canvas--4 svg")
    this.issuersGroup1 = this.block.querySelector("#issuers-group-1")
    this.issuersGroup2 = this.block.querySelector("#issuers-group-2")
    this.issuersCardNumber = this.block.querySelectorAll("#issuers-card-number path")
    this.issuersCardExpiry = this.block.querySelectorAll("#issuers-card-expiry path")
    this.issuersCardVerification = this.block.querySelectorAll("#issuers-card-verification path")
    this.issuersPlanBox = this.block.querySelector("#issuers-plan-box")
    this.issuersPlanText = this.block.querySelectorAll("#issuers-plan-text path")
    this.issuersTickBox = this.block.querySelector("#issuers-tick-box")
    this.issuersTickMark = this.block.querySelector("#issuers-tick-mark")
    this.issuersStatementInner = this.block.querySelector("#issuers-statement-inner")
    this.issuersJanuary = this.block.querySelector("#issuers-january")
    this.issuersFebruary = this.block.querySelector("#issuers-february")
    this.issuersMarch = this.block.querySelector("#issuers-march")
    this.issuersApril = this.block.querySelector("#issuers-april")
    this.issuersMay = this.block.querySelector("#issuers-may")
    this.issuersJune = this.block.querySelector("#issuers-june")
    this.issuersAccount = this.block.querySelector("#issuers-account")
    this.issuersLast = this.block.querySelector("#issuers-last")
    this.issuersPending = this.block.querySelector("#issuers-pending")
    this.issuersStatementHeader = this.block.querySelector("#issuers-statement-header")

    gsap.set(this.issuersSvg, {
      opacity: 0,
    })
    gsap.set(this.issuersGroup2, {
      opacity: 0,
    })
    gsap.set([this.issuersCardNumber, this.issuersCardExpiry, this.issuersCardVerification], {
      opacity: 0,
    })
    gsap.set([this.issuersTickBox, this.issuersTickMark], {
      opacity: 0,
    })
    gsap.set(this.issuersPlanBox, {
      opacity: 0,
    })
    gsap.set(this.issuersPlanText, {
      attr: {
        fill: "#000000",
      },
    })
    gsap.set([this.issuersJanuary, this.issuersFebruary], {
      transformOrigin: "center center",
    })

    this.issuersTimeline.to(this.issuersSvg, {
      opacity: 1,
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.issuersTimeline.to(this.issuersCardNumber, {
      opacity: 1,
      duration: 0,
      stagger: 0.05,
      ease: "sine.inOut",
    })

    this.issuersTimeline.to(this.issuersCardExpiry, {
      opacity: 1,
      duration: 0,
      stagger: 0.05,
      ease: "sine.inOut",
    })

    this.issuersTimeline.to(this.issuersCardVerification, {
      opacity: 1,
      duration: 0,
      stagger: 0.05,
      ease: "sine.inOut",
    })

    this.issuersTimeline.to(
      this.issuersPlanBox,
      {
        opacity: 1,
        duration: 0,
        ease: "sine.inOut",
      },
      "+=0.5"
    )

    this.issuersTimeline.to(this.issuersPlanText, {
      attr: {
        fill: "#FFFFFF",
      },
      duration: 0,
      ease: "sine.inOut",
    })

    this.issuersTimeline.to(
      [this.issuersTickBox, this.issuersTickMark],
      {
        opacity: 1,
        duration: 0,
        ease: "sine.inOut",
      },
      "+=0.5"
    )

    this.issuersTimeline.to(
      this.issuersGroup1,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.issuersTimeline.to(
      this.issuersGroup2,
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.issuersTimeline.to(this.issuersJanuary, {
      scale: 1.25,
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.issuersTimeline.to(
      [this.issuersFebruary, this.issuersMarch, this.issuersApril, this.issuersMay, this.issuersJune, this.issuersLast, this.issuersPending, this.issuersAccount, this.issuersStatementHeader],
      {
        opacity: 0.125,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.issuersTimeline.to(
      this.issuersJanuary,
      {
        scale: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )

    this.issuersTimeline.to(
      [this.issuersFebruary, this.issuersMarch, this.issuersApril, this.issuersMay, this.issuersJune, this.issuersLast, this.issuersPending, this.issuersAccount, this.issuersStatementHeader],
      {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.issuersTimeline.to(this.issuersStatementInner, {
      y: "-=500",
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.issuersTimeline.to(this.issuersFebruary, {
      scale: 1.25,
      duration: 0.5,
      ease: "sine.inOut",
    })

    this.issuersTimeline.to(
      [this.issuersJanuary, this.issuersMarch, this.issuersApril, this.issuersMay, this.issuersJune, this.issuersLast, this.issuersPending, this.issuersAccount, this.issuersStatementHeader],
      {
        opacity: 0.125,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "-=0.5"
    )

    this.issuersTimeline.to(
      this.issuersSvg,
      {
        opacity: 0,
        duration: 0.5,
        ease: "sine.inOut",
      },
      "+=1"
    )
  }

  animate() {
    this.elapsedTime = this.clock.getElapsedTime()

    if (this.animationActive) {
      this.renderer.render(this.scene, this.camera)
      window.requestAnimationFrame(this.animate.bind(this))
    }
  }
}

export default ParallaxContent2
